import styled from '@emotion/styled';
import {colors} from '../../ui/theme';

export const MegaMenuStyles = styled.div`
    .menu-products-dropdown {
        width: 100%;
        background-color: ${colors.white[100]};
        position: absolute;
        z-index: 1026;
        height: 499px;
        box-sizing: border-box;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        border-bottom: 0;
    }

    .menu-deals-dropdown {
        width: 100%;
        background-color: ${colors.white[100]};
        position: absolute;
        z-index: 1026;
        height: inherit;
        box-sizing: border-box;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        border-bottom: 0;
    }

    .emergency-preparedness-nav {
        margin: 0;
        background-color: ${colors.gray[100]};
        padding: 16px;
    }

    ul.shopby-links {
        list-style: none;
        margin: 18px 0 0;
        padding: 0;

        li {
            float: left;
            margin-left: 28px;

            .fa {
                font-size: 18px;
                margin-right: 4px;
            }
        }
        li:first-child {
            margin-left: 0;
        }
    }

    .navBar {
        width: 100%;
        background-color: ${colors.gray[600]};
        padding: 0;
    }

    .topnav {
        padding: 0 20px;
        font-size: 14px;
        color: ${colors.gray[150]};

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            &.left-main-nav {
                font-weight: bold;
                font-size: 14px;
                float: left;
            }

            &.right-main-nav {
                float: right;
            }
        }

        li {
            float: left;
            cursor: pointer;
        }

        .left-main-nav li {
            margin-right: 18px;
            height: 40px;
            line-height: 40px;

            a,
            button {
                display: block;
                background: none;
                font-weight: bold;
                color: ${colors.gray[150]} !important;

                &:visited {
                    color: ${colors.white[100]};
                }
            }

            &.active {
                color: ${colors.gray[150]};
            }

            &.active:after {
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 9px solid ${colors.white[100]};
                content: '';
                display: block;
                margin: -9px auto;
            }

            a:hover {
                color: ${colors.white[100]} !important;
            }

            &:hover {
                color: ${colors.white[100]} !important;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        .right-main-nav li {
            height: 40px;
            margin-left: 16px;
            line-height: 40px;

            a,
            button {
                display: block;
                background: none;
                color: ${colors.gray[150]} !important;

                &:visited {
                    color: ${colors.white[100]};
                }
            }

            a:hover,
            button:hover {
                color: ${colors.white[100]} !important;
            }
        }

        a:hover {
            text-decoration: none !important;
        }
    }

    .quick-add-dropdown {
        background-color: ${colors.white[100]};
        height: 499px;
        overflow: hidden;
        position: absolute;
        transition: all 0.3s ease-in-out;
        width: 100%;
        z-index: 1100;

        .quickAddForm__columnHeader {
            color: ${colors.gray[650]};
            font-size: 13px;
            padding-bottom: 10px;
            text-align: left;
            margin-top: 14px;
        }

        ul {
            list-style: none;
            margin: 0;

            li {
                width: 100%;
                float: left;
                margin-right: 26px;
                padding-right: 14px;
                margin-bottom: 20px;
            }
        }

        .item {
            width: calc(100% - 74px);
            padding-bottom: 10px;
        }

        .item-thumb,
        .item,
        .item-body {
            float: left;
        }

        .item-add-to-cart {
            float: right;
            margin-top: 20px;

            .fa-shopping-cart {
                color: ${colors.white[100]};
            }

            input {
                width: 40px;
            }
        }

        .item-thumb {
            margin-right: 10px;
        }

        .product-description {
            max-width: 204px;
        }
    }
`;
