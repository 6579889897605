import * as React from 'react';

export const FocusedFooter = () => (
    <>
        <div className="p-3 footer">
            <div className="row mb-3">
                <div className="text-center col">
                    <ul className="footerNav">
                        <li>
                            <a href="tel:1-800-558-2808">Call 1-800-558-2808</a>
                        </li>
                        <li>
                            <a href="/contact-us">Contact Us</a>
                        </li>
                        <li className="d-md-none">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    (window as any).LC_API.open_chat_window();
                                }}
                            >
                                Chat Now
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="text-center col">
                    <ul className="footerNav">
                        <li>
                            <a href="/content/privacy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/content/terms">Terms of Sale</a>
                        </li>
                        <li>
                            <a href="/content/terms-of-access">Terms of Access</a>
                        </li>
                        <li>
                            <a
                                id="ot-sdk-btn"
                                className="ot-sdk-show-settings pointer"
                            >
                                Your Privacy Choices
                            </a>
                        </li>
                        <li>
                            <a href="/help">Help</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="text-center">© {new Date().getFullYear()} Imperial Supplies LLC. All Rights Reserved.</div>
        </div>
        <div
            className="livechat_button d-print-none d-none d-md-block"
            data-id="TTgNdamL2Yv"
        >
            <a
                href="https://www.livechatinc.com/?utm_source=chat_button&utm_medium=referral&utm_campaign=lc_7582451"
                style={{display: `none`}}
            >
                Chat Now
            </a>
        </div>
    </>
);
