import * as React from 'react';
import {useMemo} from 'react';

import {HeaderOrderButton} from '../header-order-button/HeaderOrderButton';
import {User} from '../../users/user.class';

export interface FocusedHeaderProps {
    hideHeaderOrderButton: boolean;
    userData: User;
}

export const FocusedHeader = ({hideHeaderOrderButton, userData}: FocusedHeaderProps) => {
    const user = useMemo(() => new User(userData), [userData]);

    /**
     * Template
     */
    return (
        <div>
            <div className="focused-header gray-100-bg d-flex align-items-center">
                <div className="container-xl">
                    <div>
                        <div
                            className={`tw-flex tw-text-center ${
                                hideHeaderOrderButton ? 'tw-justify-center' : ' tw-justify-between tw-align-middle tw-pt-1.5'
                            }`}
                        >
                            <div>
                                <a
                                    href="/"
                                    id="header_imperialLogo"
                                >
                                    <img
                                        alt="Imperial Logo"
                                        src="/dimg/imperial_logo_rgb.svg"
                                        width="80"
                                    />
                                </a>
                            </div>
                            {!hideHeaderOrderButton && (
                                <HeaderOrderButton
                                    hasActiveOrder={user.hasActiveOrder()}
                                    numOrderLines={parseInt(user.orderLines)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="progress imp-progress bg-red d-lg-none d-none"
                style={{marginBottom: `-4px`}}
            >
                <div className="indeterminate" />
            </div>
        </div>
    );
};
