import * as React from 'react';
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ImpressionTracker from '../../analytics/impression-tracker/ImpressionTracker';
import {gaLegacySiteSearch} from '../../../client/ga/ga-legacy.functions';
import {Item} from '../../items/item.class';
import {OrdersService} from '../../../client/orders/orders.service';
import {Price} from '../../pricing/Price';
import {ProductSuggestion} from '../../search/search-suggestions/search-suggestions.class';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {SignalsService} from '../../../client/search/signals/signals.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface SearchOverlayResultsProps {
    closeOverlay: () => void;
    currentProductsQueryID: string;
    index: number;
    productSuggestion: ProductSuggestion;
    recordSearchTerm: () => void;
    searchString: string;
    suggestionType: string;
    user: User;
}

export const SearchOverlayProductSuggestion = ({
    closeOverlay,
    currentProductsQueryID,
    index,
    productSuggestion,
    recordSearchTerm,
    searchString,
    suggestionType,
    user,
}: SearchOverlayResultsProps) => {
    const componentName = `SearchOverlayProductSuggestion`;
    const ordersService: OrdersService = useService(`ordersService`);
    const signalsService: SignalsService = useService(`signalsService`);

    /**
     * TBD
     */
    const handleClick = () => {
        recordSearchTerm();
        const list = suggestionType === `partNumbers` ? `search_overlay_exact_match` : `search_overlay_products`;
        gaLegacySiteSearch(searchString, `typeahead_search`, true);
        signalsService
            .sendSignals([
                {
                    params: {
                        collection: `ImperialItems`,
                        docId: productSuggestion.id,
                        fusion_query_id: currentProductsQueryID,
                        res_pos: index + 1,
                        source: list,
                    },
                    type: `click`,
                },
            ])
            .catch((sendSignalsErr) => {
                // fail silently, most likely a navigation before signals finish saving
                // eslint-disable-next-line no-console
                console.log(`Error sending fusion signals`, sendSignalsErr);
            });
        pushGaEvent(`ga4_search_overlay_click`, {
            click_type: `search_overlay_exact_match`,
            search_input: searchString,
            selection_id: productSuggestion.id,
            selection_name: productSuggestion.name,
        });
    };

    /**
     * Template
     */
    return (
        <>
            {index < 6 && (
                <ImpressionTracker
                    as="div"
                    classes="d-lg-none d-block product_view w-100"
                    data={{
                        dimension16: ordersService?.currentOrderNumber,
                        index,
                        item_id: productSuggestion.id,
                        item_list_name: suggestionType === `partNumbers` ? `search_overlay_exact_match` : `search_overlay_products`,
                    }}
                >
                    <div
                        className="productLink pointer"
                        onClick={() => {
                            handleClick();
                            location.assign(`/item/${productSuggestion.id}`);
                        }}
                    >
                        <img
                            alt={productSuggestion.name}
                            className="p-3"
                            height="75"
                            src={`/ProductImageThumbs75${productSuggestion.imagePath || '/noimage.png'}`}
                            width="75"
                        />
                        <div className="flex-grow-1 my-2">
                            {productSuggestion.prevPurchased && <span className="badge badge-success">Purchased</span>}
                            <div>{Item.prettyItem(productSuggestion.id || ``)}</div>
                            <div className="body">{productSuggestion.name}</div>
                            {!(productSuggestion.isGDSP && productSuggestion.discontinued) && (
                                <Price
                                    alias={componentName}
                                    className="caption"
                                    item={productSuggestion}
                                    key={`price_${suggestionType}_${index}`}
                                    onModalDisplay={() => closeOverlay()}
                                    user={user}
                                />
                            )}
                        </div>
                    </div>
                </ImpressionTracker>
            )}
            <ImpressionTracker
                classes="col mb-3 d-lg-block d-none"
                data={{
                    dimension16: ordersService?.currentOrderNumber,
                    index,
                    item_id: productSuggestion.id,
                    item_list_name: suggestionType === `partNumbers` ? `search_overlay_exact_match` : `search_overlay_products`,
                }}
                trackClicks={false}
            >
                <div className="card h-100 searchOverlay__searchProductResult border-left-0 border-right-0 border-bottom-0">
                    <div className="card-body d-flex align-items-start flex-column">
                        <div className="mb-auto">
                            <ImpressionTracker
                                as="span"
                                data={{
                                    dimension16: ordersService?.currentOrderNumber,
                                    index,
                                    item_id: productSuggestion.id,
                                    item_list_name:
                                        suggestionType === `partNumbers` ? `search_overlay_exact_match` : `search_overlay_products`,
                                }}
                                trackImpressions={false}
                            >
                                <a
                                    className="text-center pt-3 d-block"
                                    href={`/item/${productSuggestion.id}`}
                                    onClick={handleClick}
                                >
                                    <div className="text-center mb-3">
                                        <img
                                            alt={productSuggestion.name}
                                            height="75"
                                            src={`/ProductImageThumbs75${productSuggestion.imagePath || '/noimage.png'}`}
                                            width="75"
                                        />
                                    </div>
                                    <div className="text-left">
                                        <div className="caption gray-650">{Item.prettyItem(productSuggestion.id || ``)}</div>
                                        {productSuggestion.prevPurchased && <span className="badge badge-success">Purchased</span>}
                                        <div className="body">{productSuggestion.name}</div>
                                    </div>
                                </a>
                            </ImpressionTracker>
                        </div>
                        {!(productSuggestion.discontinued && (productSuggestion.isGDSP || productSuggestion.inStock === false)) && (
                            <Price
                                alias={componentName}
                                className="caption gray-650 mt-1"
                                item={productSuggestion}
                                key={`price_${suggestionType}_${index}`}
                                onModalDisplay={() => closeOverlay()}
                                user={user}
                            />
                        )}
                    </div>
                    <div className="card-footer border-top-0 bg-white pt-0">
                        <div className="productResult__bc caption gray-650">
                            <a
                                onClick={() => {
                                    gaLegacySiteSearch(searchString, `typeahead_search`, true);
                                    pushGaEvent(`ga4_search_overlay_click`, {
                                        click_type: `search_overlay_similar_products`,
                                        search_input: searchString,
                                        selection_id: productSuggestion.id,
                                        selection_name: productSuggestion.name,
                                    });
                                }}
                                href={`/browse/${productSuggestion.categoryPath}`}
                            >
                                <FontAwesomeIcon icon={faChevronCircleRight} /> View Similar Products
                            </a>
                        </div>
                    </div>
                </div>
            </ImpressionTracker>
        </>
    );
};
