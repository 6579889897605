import * as React from 'react';
import * as yup from 'yup';
import {Button} from 'react-bootstrap';
import {FormProvider, useForm} from 'react-hook-form';
import {useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import {Alerts} from '../../ui/Alerts/Alerts';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {Input} from '../../ui/forms/Input/Input';
import {MROMatch} from '../../search/search-suggestions/search-suggestions.class';
import {MRORequestParams} from '../../search/search-result/search-result.class';
import {SearchService} from '../../../client/search/search.service';
import {TextArea} from '../../ui/forms/TextArea/TextArea';
import {useService} from '../../react/ServiceContext';

interface MROResultProps {
    mroMatch: MROMatch;
}

export const MROResult = ({mroMatch}: MROResultProps) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showSuccess, setShowSuccess] = useState(false);
    const searchService: SearchService = useService(`searchService`);

    // Setup form
    const schema = yup
        .object({
            mroComment: yup.string().optional().max(900).label(`Comment`),
            mroEmail: yup.string().required().max(45).email().label(`E-Mail Address`),
            mroName: yup.string().required().max(45).label(`Name`),
            mroPhone: yup.string().required().label(`Phone Number`),
            mroQty: yup.string().optional().label(`Desired Order Qty`),
        })
        .required();
    const form = useForm({resolver: yupResolver(schema)});
    const formValues = form.watch();

    /**
     * Submits form
     */
    const submitForm = () => {
        setShowSuccess(false);
        setErrorMessage(null);
        const mroRequestParams: MRORequestParams = {
            comment: formValues.mroComment,
            email: formValues.mroEmail,
            itemID: mroMatch.mroItem,
            name: formValues.mroName,
            orderQuantity: formValues.mroQty,
            phone: formValues.mroPhone,
        };
        searchService
            .sendMRORequest(mroRequestParams)
            .then(() => {
                setShowSuccess(true);
            })
            .catch((sendMRORequestErr: ImpError) => {
                setErrorMessage(sendMRORequestErr.message);
            });
    };

    /**
     * Template
     */
    return (
        <div className="col-12">
            <div className="row">
                <div className="col-lg-3 mb-3">
                    <div className="overlay_head border-bottom mb-3 border-top-0 mx-n3 mx-md-0">
                        <h5>Exact Match</h5>
                    </div>
                    <div className="row">
                        <div className="col-3 col-md-2 col-lg-12 text-center">
                            <img
                                alt={mroMatch.description}
                                className="p-3 p-md-0"
                                height="75"
                                src={`/mro-image/${mroMatch.mroItem}`}
                                width="75"
                            />
                        </div>
                        <div className="col-9 col-md-10 pt-3 pt-md-0">
                            <div className="caption">{mroMatch.mroItem}</div>
                            <div>{mroMatch.description}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <FormProvider {...form}>
                        <form
                            onSubmit={(event) => {
                                form.handleSubmit(submitForm)(event).catch((formError) => {
                                    setErrorMessage(formError.message);
                                });
                            }}
                        >
                            <div className="text-center gray-150-bg p-3">
                                <h4 className="mb-0">We can get this item for you.</h4>
                            </div>
                            <div className="gray-100-bg p-4 mb-3">
                                {showSuccess && (
                                    <Alerts
                                        message="Success! Your item inquiry has been sent."
                                        variant="success"
                                    />
                                )}
                                <Alerts
                                    message={errorMessage}
                                    variant="danger"
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input
                                            error={form?.formState?.errors?.mroName?.message as string}
                                            id="mroName"
                                            label="Name"
                                            maxLength={45}
                                            name="mroName"
                                            required={true}
                                            type="text"
                                            autocorrect="off"
                                            {...form.register(`mroName`)}
                                        />
                                        <Input
                                            error={form?.formState?.errors?.mroEmail?.message as string}
                                            id="mroEmail"
                                            label="E-mail Address"
                                            maxLength={45}
                                            name="mroEmail"
                                            required
                                            type="text"
                                            autocorrect="off"
                                            {...form.register(`mroEmail`)}
                                        />
                                        <Input
                                            error={form?.formState?.errors?.mroPhone?.message as string}
                                            formatPhoneNumber={true}
                                            label="Phone Number"
                                            name="mroPhone"
                                            numbersOnly={true}
                                            {...form.register(`mroPhone`)}
                                        />
                                        <Input
                                            error={form?.formState?.errors?.mroQty?.message as string}
                                            id="mroQty"
                                            label="Desired Order Qty"
                                            name="mroQty"
                                            type="number"
                                            {...form.register(`mroQty`)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextArea
                                            className="mb-1 pb-4"
                                            displayCounter={true}
                                            error={form?.formState?.errors?.mroComment?.message as string}
                                            placeholder="Comment"
                                            maxLength={900}
                                            name="mroComment"
                                            style={{resize: `none`}}
                                            {...form.register(`mroComment`)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <Button
                                            block={true}
                                            className="md:btn-lg"
                                            type="submit"
                                            variant="secondary"
                                        >
                                            Submit Inquiry
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                    <div className="text-center">
                        <h5 className="font-weight-bold">Contact Dedicated Service Advisor</h5>
                        <div>
                            Call 1-800-558-2808<span className="tw-ml-3">|</span>
                            <Button
                                onClick={() => {
                                    (window as any)?.LC_API?.open_chat_window();
                                }}
                                variant="link"
                            >
                                Start Chat
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
