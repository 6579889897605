import styled from '@emotion/styled';

export const FlyoutMenuStyles = styled.div`
    .flyoutContainer {
        opacity: 0;
    }
    .flyoutContainer.show {
        background-color: #333333;
        opacity: 0.5;
        height: 100%;
        bottom: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1026;
        left: 0;
        right: 0;
        transition: opacity 0.2s ease;
    }

    .flyoutMenu {
        background: #ffffff;
        width: 80%;
        max-height: 100vh !important;
        height: 100vh !important;
        position: fixed;
        top: 0;
        right: 0;
        transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
        z-index: 1040;
    }

    .flyoutBody {
        font-size: 1rem;
        overflow: auto;
        height: calc(100vh - 62px);
    }
`;
