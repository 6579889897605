import * as React from 'react';
import {faCircleUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';

import {AccountFinderModal} from '../../authentication/AccountFinderModal/AccountFinderModal';
import {BodyScroll} from '../../ui/ScrollHandler';
import {FlyoutMenu} from '../../ui/Flyouts/FlyoutMenu';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {LinkButton} from '../../ui/LinkButton/LinkButton';
import {MyAccountContainer, MyAccountFlyoutStyle} from './MyAccountFlyout.style';
import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {UserStateService} from '../../../client/users/user-state.service';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface MyAccountFlyoutProps {
    user: User;
}

export const MyAccountFlyout = ({user}: MyAccountFlyoutProps) => {
    const [hydrated, setHydrated] = React.useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [showAccountFinderModal, setShowAccountFinderModal] = useState(false);
    const componentName = `my-account-flyout-component`;
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);
    const userStateService: UserStateService = useService(`userStateService`);

    useEffect(() => {
        setHydrated(true);
    }, []);

    /**
     * Creates new order
     */
    const createNewOrder = () => {
        ordersWorkflow.createOrderNavOrderDetail(componentName).subscribe({
            error: (createOrderErr: ImpError) => {
                alert(createOrderErr.message);
            },
        });
    };

    // Process queued actions
    useEffect(() => {
        if (userStateService.hasPendingAction(componentName, `createOrder`)) {
            createNewOrder();
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Toggles display of My Account menu
     */
    const toggleMyAccountFlyout = () => {
        if (user.isLoggedIn()) {
            setMenuOpen(true);
            BodyScroll(false);
        }
    };

    /**
     * Template
     */
    return (
        <>
            <MyAccountContainer>
                <span
                    className="tw-flex tw-items-center tw-cursor-pointer"
                    data-e2e="myAccountLink"
                    tabIndex={0}
                    onClick={() => {
                        toggleMyAccountFlyout();
                    }}
                    onFocus={() => {
                        toggleMyAccountFlyout();
                    }}
                >
                    <FontAwesomeIcon
                        className="tw-mr-2 tw-text-gray-600"
                        icon={faCircleUser}
                        size="lg"
                    />{' '}
                    <span className="d-inline gray-600 h6 mb-0">Account</span>
                </span>
            </MyAccountContainer>
            <MyAccountFlyoutStyle>
                <FlyoutMenu
                    data-e2e="manageAccountFlyout"
                    onClose={() => {
                        setMenuOpen(false);
                        BodyScroll(true);
                    }}
                    show={menuOpen}
                    title="Welcome"
                >
                    <div className="p-2">
                        <>
                            {!user.activeAccount ? (
                                <div className="empty-state">
                                    You must&nbsp;
                                    <a
                                        className="text-link"
                                        onClick={() => {
                                            setMenuOpen(false);
                                            BodyScroll(true);
                                            setShowAccountFinderModal(true);
                                        }}
                                    >
                                        select an account
                                    </a>
                                    &nbsp;to see your open orders
                                </div>
                            ) : (
                                <div className="my-account-dropdown visible">
                                    <div className="row px-2">
                                        {user.isLoggedIn() && (
                                            <>
                                                <div className="col-sm-6 mb-4">
                                                    <ul className="account-links">
                                                        <li className="pb-2">
                                                            <h5 className="font-weight-bolder">My Orders</h5>
                                                            <ul>
                                                                {user.canOrder() && (
                                                                    <>
                                                                        <li>
                                                                            <a href="/orders">Order Review</a>
                                                                        </li>
                                                                        {!user.isPunchout && (
                                                                            <li>
                                                                                <a href="/manageautoreorders">Auto-Reorder Items</a>
                                                                            </li>
                                                                        )}
                                                                        <li>
                                                                            <a href="/special-order">Special Order Review</a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                                {user.hasOrderApprovalAccess && (
                                                                    <li>
                                                                        <a href="/orders/approval">Order Approval</a>
                                                                    </li>
                                                                )}
                                                                {user.canOrder() && (
                                                                    <>
                                                                        <li>
                                                                            <a href="/returns?numMonths=6">My Returns</a>
                                                                        </li>
                                                                        {user.hasActiveOrder() && !user.isPunchout && hydrated ? (
                                                                            <li>
                                                                                <a
                                                                                    href={`/orders/${ordersService.currentOrderNumber}/checkout`}
                                                                                >
                                                                                    Check Out Order {ordersService.currentOrderNumber}
                                                                                </a>
                                                                            </li>
                                                                        ) : (
                                                                            <li>
                                                                                <LinkButton
                                                                                    customStyle={{color: '#333333'}}
                                                                                    onClick={() => {
                                                                                        setMenuOpen(false);
                                                                                        createNewOrder();
                                                                                    }}
                                                                                    text="Create Order"
                                                                                />
                                                                            </li>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="pb-2">
                                                            <h5 className="font-weight-bolder">Invoices & Statements</h5>
                                                            <ul>
                                                                {user.canInvoice() && (
                                                                    <li>
                                                                        <a href="/invoices">Invoice Review</a>
                                                                    </li>
                                                                )}
                                                                {user.hasARAccess() && (
                                                                    <li>
                                                                        <a href="/ar">Invoice Payment</a>
                                                                    </li>
                                                                )}
                                                                {user.hasSalesAccess() && user.canViewPrice() && (
                                                                    <li>
                                                                        <a href="/discounts">Discount Review</a>
                                                                    </li>
                                                                )}
                                                                {user.hasJobCosting() && (
                                                                    <li>
                                                                        <a href="/jobcosting">Repair Order Review</a>
                                                                    </li>
                                                                )}
                                                                {user.hasRepairOrder() && (
                                                                    <li>
                                                                        <a href="/ro">Repair Orders</a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="pb-2">
                                                            <h5 className="font-weight-bolder">Reporting</h5>
                                                            <ul>
                                                                {user.hasSalesAccess() && user.canViewPrice && (
                                                                    <>
                                                                        <li>
                                                                            <a href="/spend-overview">Spend Overview Dashboard</a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-6 mb-4">
                                                    <ul className="account-links">
                                                        <li className="pb-2">
                                                            <h5 className="font-weight-bolder">Reorder</h5>
                                                            <ul>
                                                                {user.canOrder() && (
                                                                    <>
                                                                        <li>
                                                                            <a href="/order-history-items">Purchased Products</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="/fileimport/">File Import</a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="/lists/">Lists</a>
                                                                        </li>
                                                                    </>
                                                                )}
                                                                {user.canOrder() && (
                                                                    <li>
                                                                        <a href="/quickadd">Quick-Add</a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="pb-2">
                                                            <h5 className="font-weight-bolder">My Shop</h5>
                                                            <ul>
                                                                {user.isAccountLevel() && (
                                                                    <li>
                                                                        <a href="/my-shop">Locations</a>
                                                                    </li>
                                                                )}
                                                                <li>
                                                                    <a href="/my-shop/inventory">Inventory Items</a>
                                                                </li>
                                                                <li>
                                                                    <a href="/my-shop/steel-equipment">Steel Equipment</a>
                                                                </li>
                                                                <li>
                                                                    <a href="/my-sds">Safety Data Sheets</a>
                                                                </li>
                                                                <li>
                                                                    <a href="/my-shop/backsheets">Backsheets</a>
                                                                </li>
                                                                {user.isFieldService() && (
                                                                    <li>
                                                                        <a href="/fs-photo-uploader">Upload Photos</a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </li>
                                                        <li className="pb-2">
                                                            <h5 className="font-weight-bolder">Account Management</h5>
                                                            <ul>
                                                                {user.canSwitchAccount() && (
                                                                    <li style={{lineHeight: 1}}>
                                                                        <LinkButton
                                                                            customStyle={{color: '#333333'}}
                                                                            onClick={() => {
                                                                                setMenuOpen(false);
                                                                                BodyScroll(true);
                                                                                setShowAccountFinderModal(true);
                                                                            }}
                                                                            text={
                                                                                <>
                                                                                    Switch Account
                                                                                    <div className="caption text-left">
                                                                                        Current:{' '}
                                                                                        {user.activeAccount
                                                                                            ? user.activeAccount
                                                                                            : 'No Account Selected'}{' '}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        />
                                                                    </li>
                                                                )}
                                                                {user.isAccountLevel() && (
                                                                    <li>
                                                                        <a href="/account-info">Account Review</a>
                                                                    </li>
                                                                )}
                                                                <li>
                                                                    <a href="/account#change-password">Change Password</a>
                                                                </li>
                                                                <li>
                                                                    <a href="/email-preferences">Email Preferences</a>
                                                                </li>
                                                                {user.invoicePrefAccess && (
                                                                    <li>
                                                                        <a href="/invoice-delivery-preferences">
                                                                            Invoice Delivery Preferences
                                                                        </a>
                                                                    </li>
                                                                )}
                                                                <li>
                                                                    <a href="/payment-methods">Payment Methods</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="row px-2 mb-3">
                                        <div className="col">
                                            <a
                                                className="px-2 btn btn-secondary btn-lg btn-block"
                                                href="/account"
                                                role="button"
                                            >
                                                View My Account
                                            </a>
                                            <a
                                                className="px-2 btn btn-outline-secondary btn-lg btn-block mt-3"
                                                href="/logout"
                                                role="button"
                                            >
                                                Log out
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </FlyoutMenu>
            </MyAccountFlyoutStyle>
            <AccountFinderModal
                onHide={() => setShowAccountFinderModal(false)}
                selectAccountMode={user.useSelectAccountMode()}
                show={showAccountFinderModal}
                user={user}
            />
        </>
    );
};
