import {appComponent} from '../app.component';
import {UsersService} from '../users/users.service';
import {UserStateService} from '../users/user-state.service';
import {User} from '../../shared/users/user.class';

export class ScannerWorkflow {
    private readonly _currentUser: User;

    constructor(private _usersService: UsersService, private _userStateService: UserStateService) {
        this._currentUser = this._usersService.getCurrentUser();
    }

    /**
     * Displays scanner
     * @param componentName
     * @param action
     */
    public showScanner(componentName: string, action: `hamburger_menu` | `header` | `scanner_reload` = 'header') {
        // Record showScanner action
        this._userStateService.recordPendingAction(componentName, `showScanner`);

        // If not logged in, showLoginPopUp
        if (!this._currentUser.isLoggedIn()) {
            this._usersService.showLoginPopUp();
            return;
        }

        // If not activeAccount, present AccountPickerComponent in select mode
        if (!this._currentUser.activeAccount) {
            this._usersService.showAccountPicker(true);
            return;
        }

        // Else show scanner
        this._userStateService.clearPendingAction();
        if (appComponent) {
            appComponent.openScanner(action);
        } else {
            // Else wait for appComponent to become available
            setTimeout(() => {
                appComponent.openScanner(action);
            }, 100);
        }
    }
}
