import * as React from 'react';
import {Button} from 'react-bootstrap';
import {debounce} from 'lodash';
import {faSearch, faTimes, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {signal} from '@preact/signals-react';
import {useCallback, useRef, useState} from 'react';

import {Alerts} from '../../ui/Alerts/Alerts';
import {BodyScroll} from '../../ui/ScrollHandler';
import {gaLegacyCustomEvent, gaLegacySiteSearch} from '../../../client/ga/ga-legacy.functions';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ItemSpinner} from '../../ui/item-spinner/ItemSpinner';
import {ItemsService} from '../../../client/items/items.service';
import {LocalStorageService} from '../../../client/local-storage/local-storage.service';
import {MROResult} from './MROResult';
import {NullResults} from './NullResults';
import {OrderableItem, PriceInfo} from '../../items/item.class';
import {ProductSuggestion, SearchSuggestions} from '../../search/search-suggestions/search-suggestions.class';
import {pushGaEvent} from '../../../client/ga/ga.functions';
import {SearchOverlayResults} from './SearchOverlayResults';
import {SearchService} from '../../../client/search/search.service';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface SearchOverlayProps {
    initialSearchString: string;
    user: User;
    dataE2e: string;
}

const cursorPosition = signal([0, 0]);
const KEY_DEBOUNCE_DELAY = 300;
const MAX_DISPLAY_TERMS = 12;
const MAX_STORE_TERMS = 50;
const MIN_SEARCH_TERM_LEN = 3;

export const SearchOverlay = ({initialSearchString, user, dataE2e}: SearchOverlayProps) => {
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [recentlyViewedItems, setRecentlyViewedItems] = useState<OrderableItem[]>();
    const [searchError, setSearchError] = useState<string>();
    const [searchResults, setSearchResults] = useState<SearchSuggestions>();
    const [searchString, setSearchString] = useState(initialSearchString || ``);
    const [searchTerms, setSearchTerms] = useState<string[]>();
    const itemsService: ItemsService = useService(`itemsService`);
    const localStorageService: LocalStorageService = useService(`localStorageService`);
    const mainInputRef = useRef<HTMLInputElement>();
    const searchButtonRef = useRef<HTMLButtonElement>();
    const searchService: SearchService = useService(`searchService`);

    /**
     * Get array of recent search terms from local storage
     */
    const _getSearchTerms = useCallback(
        (searchTerm: string): string[] => {
            let termHistoryArray = [];
            const filteredTermHistoryArray = [];
            const termHistoryString = localStorageService.getItem(`termHistory`);

            // Convert termHistoryString to termHistoryArray
            if (termHistoryString) {
                termHistoryArray = termHistoryString.split(`,`).reverse();
            } else {
                return termHistoryArray;
            }
            if (!searchTerm) {
                return termHistoryArray.slice(0, MAX_DISPLAY_TERMS);
            }

            // Filter termHistoryArray using searchTerm
            const searchStringRegex = TemplateHelpers.regexSafeFilterVal(searchTerm);
            for (const nextSearchTerm of termHistoryArray) {
                if (nextSearchTerm.match(searchStringRegex)) {
                    filteredTermHistoryArray.push(nextSearchTerm);
                }
            }
            return filteredTermHistoryArray;
        },
        [localStorageService],
    );

    /**
     * Load prices for recent search result
     */
    const _getSuggestedProductPrices = useCallback(
        (_searchSuggestions: SearchSuggestions) => {
            const combinedProductSuggestions = _searchSuggestions.products.concat(_searchSuggestions.partNumbers);
            if (combinedProductSuggestions.length) {
                itemsService
                    .addPricesForItemTypeArray(combinedProductSuggestions)
                    .then((getPricesFromItemNumArrayRes: ProductSuggestion[]) => {
                        const priceMap: Map<string, PriceInfo> = new Map();
                        getPricesFromItemNumArrayRes.forEach((suggestionResult) => {
                            priceMap.set(suggestionResult.id, suggestionResult.priceInfo);
                        });
                        const newSuggestions = {..._searchSuggestions};
                        if (newSuggestions.products) {
                            newSuggestions.products.forEach((suggestionResult) => {
                                suggestionResult.priceInfo = priceMap.get(suggestionResult.id);
                            });
                        }
                        if (newSuggestions.partNumbers) {
                            newSuggestions.partNumbers.forEach((suggestionResult) => {
                                suggestionResult.priceInfo = priceMap.get(suggestionResult.id);
                            });
                        }
                        setSearchResults(newSuggestions);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.error(e);
                    });
            }
        },
        [itemsService],
    );

    /**
     * Closes search overlay
     */
    const closeOverlay = () => {
        setOverlayOpen(false);
        BodyScroll(true);
        pushGaEvent(`ga4_search_overlay_close`, {click_type: `modal_close`});
    };

    /**
     * Execute search query
     */
    const executeSearch = useCallback(
        (newSearch: string) => {
            setSearchError(undefined);
            searchService
                .getSearchSuggestions(newSearch)
                .then((getSearchSuggestionsRes) => {
                    if (!getSearchSuggestionsRes) {
                        // If results are canceled before returning (a 2nd request sent before first returns) we get a blank callback
                        return;
                    }
                    setSearchResults(getSearchSuggestionsRes);
                    _getSuggestedProductPrices(getSearchSuggestionsRes);
                    if (
                        getSearchSuggestionsRes &&
                        getSearchSuggestionsRes.categories.length === 0 &&
                        getSearchSuggestionsRes.partNumbers.length === 0 &&
                        getSearchSuggestionsRes.products.length === 0 &&
                        !getSearchSuggestionsRes.mroMatch &&
                        !recentlyViewedItems
                    ) {
                        itemsService
                            .recentlyViewedItems()
                            .then((getRecentlyViewedItemsRes) => {
                                if (getRecentlyViewedItemsRes) {
                                    setRecentlyViewedItems(getRecentlyViewedItemsRes);
                                }
                            })
                            .catch(() => {
                                // Error silently
                            });
                    }
                })
                .catch((getSearchSuggestionsErr: ImpError) => {
                    setSearchError(getSearchSuggestionsErr.message);
                });
        },
        [_getSuggestedProductPrices, searchService, itemsService, recentlyViewedItems],
    );

    /**
     * Open the overlay
     */
    const openOverlay = useCallback(() => {
        pushGaEvent(`ga4_search_overlay_open`, {click_type: `modal_open`});
        setOverlayOpen(true);
        BodyScroll(false);
        if (searchString && !searchResults) {
            executeSearch(searchString);
        } else {
            setSearchTerms(_getSearchTerms(``));
        }
    }, [searchString, searchResults, _getSearchTerms, executeSearch]);

    /**
     * TBD
     * @param event
     */
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.code === `Escape`) {
            closeOverlay();
            searchButtonRef.current.focus();
        }
    };

    /**
     * Records analytics associated with searchTerm
     * @param searchTerm
     */
    const recordAnalytics = (searchTerm: string) => {
        searchTerm = searchTerm.replace(/["']/g, ``);
        localStorageService.setItem(`searchTerm`, searchTerm);
        gaLegacyCustomEvent({
            eventAction: `Recent Search Term`,
            eventCategory: `Search Overlay`,
            eventLabel: searchTerm,
        });
        gaLegacySiteSearch(searchTerm, `recent_search`, true);
        pushGaEvent(`ga4_site_search`, {
            search_method: `recent_search_term`,
            search_term: searchTerm,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const trySearch = useCallback(
        debounce((newSearch: string) => {
            if (newSearch.length >= MIN_SEARCH_TERM_LEN) {
                executeSearch(newSearch);
            } else {
                // Hide search results
                setSearchResults(undefined);
                if (!searchTerms) {
                    setSearchTerms(_getSearchTerms(``));
                }
            }
        }, KEY_DEBOUNCE_DELAY),
        [executeSearch, MIN_SEARCH_TERM_LEN, KEY_DEBOUNCE_DELAY, debounce],
    );

    /**
     * TBD
     * @param event
     */
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        trySearch(event.target.value);
    };

    /**
     * Record search term into recent searches in localstorage
     */
    const recordSearchTerm = () => {
        localStorageService.setItem(`searchTerm`, searchString);
        if (searchString) {
            const termHistoryString = localStorageService.getItem(`termHistory`) || ``;
            let termHistoryArray = termHistoryString.split(`,`);
            const termIndex = termHistoryArray.indexOf(searchString);
            if (termIndex === -1) {
                termHistoryArray.push(searchString);
                if (termHistoryArray.length > MAX_STORE_TERMS) {
                    termHistoryArray.shift();
                }
            } else {
                termHistoryArray.splice(termIndex, 1);
                termHistoryArray.push(searchString);
            }
            termHistoryArray = termHistoryArray.filter((term) => {
                return !!term.trim();
            });
            localStorageService.setItem(`termHistory`, termHistoryArray.join(`,`));
            setSearchTerms(termHistoryArray);
        }
    };

    /**
     * Remove search term from localstorage
     * @param searchTerm
     */
    const removeSearchTerm = (searchTerm: string) => {
        const termHistoryString = localStorageService.getItem(`termHistory`) || ``;
        const termHistoryArray = termHistoryString.split(`,`);
        termHistoryArray.splice(termHistoryArray.indexOf(searchTerm.trim()), 1);
        gaLegacyCustomEvent({
            eventAction: `Clear Single Recent Search Term`,
            eventCategory: `Search Overlay`,
            eventLabel: searchTerm.trim(),
        });
        localStorageService.setItem(`termHistory`, termHistoryArray.join(`,`));
        setSearchTerms(termHistoryArray);
    };

    /**
     * Clear all search terms from local storage
     */
    const clearAllSearchTerms = () => {
        gaLegacyCustomEvent({
            eventAction: `Clear All Recent Search Terms`,
            eventCategory: `Search Overlay`,
            eventLabel: `NA`,
        });
        localStorageService.setItem(`termHistory`, ``);
        setSearchTerms(undefined);
    };

    /**
     * Template
     */
    return (
        <>
            {overlayOpen && (
                <>
                    <div
                        className="searchOverlayWrapper"
                        onClick={() => {
                            closeOverlay();
                            searchButtonRef.current.focus();
                        }}
                    />
                    <div className="searchOverlay">
                        <div className="search-header sticky-md-top py-2">
                            <form
                                action="/search/"
                                className="searchOverlay__searchForm d-flex align-items-center m-0"
                                method="GET"
                                onSubmit={() => {
                                    recordSearchTerm();
                                    pushGaEvent(`ga4_site_search`, {search_method: `standard_search`, search_term: searchString});
                                }}
                            >
                                <div className="input-group input-group-lg ml-2 ml-lg-0 search-field">
                                    <input
                                        aria-label="Search Input Field"
                                        autoComplete="off"
                                        autoFocus={true}
                                        className="search-input form-control border-right-0 outline-0 pr-0"
                                        data-e2e="searchInput"
                                        id="searchForm__searchInput"
                                        name="SearchString"
                                        onChange={handleOnChange}
                                        onKeyUp={handleKeyUp}
                                        placeholder="Search by keyword or item #"
                                        ref={mainInputRef}
                                        type="text"
                                        value={searchString}
                                        onFocus={(e) => {
                                            e.currentTarget.setSelectionRange(cursorPosition.value[0], cursorPosition.value[1]);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span
                                            className="justify-content-center input-group-text border-left-0 border-right-0 bg-white pointer"
                                            onClick={() => {
                                                setSearchString(``);
                                                setSearchResults(undefined);
                                                setSearchTerms(_getSearchTerms(``));
                                                mainInputRef.current.focus();
                                            }}
                                            style={{width: `64px`, display: searchString ? `block` : `none`}}
                                        >
                                            <FontAwesomeIcon
                                                className="align-middle"
                                                icon={faTimesCircle}
                                            />
                                        </span>
                                        <button
                                            className="btn btn-red"
                                            id="searchForm__submitButton"
                                            type="submit"
                                        >
                                            <FontAwesomeIcon icon={faSearch} />
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="searchForm__cancelButton"
                                    onClick={() => {
                                        closeOverlay();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="mb-3 searchOverlay__searchContainer w-100 tw-overflow-x-hidden tw-h-[calc(100vh-134px)]">
                            {searchError && (
                                <div className="searchOverlay__searchResults col-lg-12 p-4 p-lg-2 s12">
                                    <Alerts
                                        message2={`Server returned error: ${searchError}`}
                                        message="Please try again later or contact an Imperial Dedicated Account Adviser for assistance at 1-800-558-2808"
                                        multiLine={true}
                                        title="We cannot return results at this time"
                                        variant="danger"
                                    />
                                </div>
                            )}
                            {searchTerms && !searchString && searchTerms.length > 0 && (
                                <div className="mb-3 searchOverlay__termsContainer w-100">
                                    <div className="mb-3">
                                        <div className="overlay_head">
                                            <span className="body float-right pt-2 pr-3 mb-0">
                                                <Button
                                                    onClick={clearAllSearchTerms}
                                                    variant="link"
                                                >
                                                    Clear All
                                                </Button>
                                            </span>
                                            <h5>Recent Searches</h5>
                                        </div>
                                        <div className="searchOverlay__fullHistory">
                                            <ul>
                                                {searchTerms.map((searchTerm, index) => (
                                                    <li key={index}>
                                                        <a
                                                            className="flex-grow-1 body"
                                                            href={`/search/?SearchString=${searchTerm}`}
                                                            onClick={() => recordAnalytics(searchTerm)}
                                                        >
                                                            {searchTerm}
                                                        </a>
                                                        <FontAwesomeIcon
                                                            className="mr-2 pointer"
                                                            color="#707070"
                                                            icon={faTimesCircle}
                                                            onClick={() => {
                                                                removeSearchTerm(searchTerm);
                                                            }}
                                                            size="2x"
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {searchResults &&
                                searchResults.categories.length === 0 &&
                                searchResults.partNumbers.length === 0 &&
                                searchResults.products.length === 0 &&
                                !searchResults.mroMatch && (
                                    <div className="searchOverlay__searchResults col-lg-12 p-4 p-lg-2 s12">
                                        <div className="mb-3">
                                            <NullResults searchString={searchString} />
                                        </div>
                                        <ItemSpinner
                                            gaList="Recently Viewed"
                                            label="Recently Viewed"
                                            loaded={!!recentlyViewedItems}
                                            maxSlides={5}
                                            orderableItems={recentlyViewedItems}
                                            useAddToOrderModal={true}
                                            user={user}
                                        />
                                    </div>
                                )}
                            {searchResults && searchResults.mroMatch && <MROResult mroMatch={searchResults.mroMatch} />}
                            {searchResults && !searchResults.mroMatch && (
                                <SearchOverlayResults
                                    closeOverlay={closeOverlay}
                                    recordSearchTerm={recordSearchTerm}
                                    searchString={searchString}
                                    searchSuggestions={searchResults}
                                    user={user}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <form
                action="/search/"
                method="GET"
            >
                <div className="input-group input-group-lg search-field">
                    <input
                        accessKey="s"
                        aria-label="Search Field"
                        autoComplete="off"
                        className="form-control search-input-static"
                        data-e2e={dataE2e}
                        name="SearchString"
                        onChange={handleOnChange}
                        onClick={(e) => {
                            const cursorStart = e.currentTarget.selectionStart;
                            const cursorEnd = e.currentTarget.selectionEnd;
                            cursorPosition.value = [cursorStart, cursorEnd];
                            if (overlayOpen === false) {
                                openOverlay();
                            }
                        }}
                        onKeyDown={(e) => {
                            if (overlayOpen === false && e.key !== `Tab`) {
                                e.currentTarget.click();
                            }
                        }}
                        placeholder="Search by keyword or item #"
                        type="text"
                        value={searchString}
                    />
                    <div className="input-group-append">
                        <button
                            aria-label="Search"
                            className="btn btn-red searchBtn"
                            data-e2e="searchBarBtn"
                            ref={searchButtonRef}
                            style={{height: `48px`}}
                            tabIndex={0}
                            type="submit"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};
