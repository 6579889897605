import safeRegex from 'safe-regex2';

export class UrlClass {
    /**
     * Add Url Parameter
     * @param url - the url
     * @param key - the actual parameter
     * @param value - value of the parameter
     */
    static addParam(url: string, key: string, value: string): string {
        const re = new RegExp(`([?&])${key}=.*?(&|$)`, `i`);
        const separator = url.indexOf('?') !== -1 ? `&` : `?`;
        if (url.match(re)) {
            return url.replace(re, `$1${key}=${value}$2`);
        } else {
            return `${url}${separator}${key}=${value}`;
        }
    }

    /**
     * Returns URL query parameter value
     * @param queryParam - query param to retrieve value for
     * @param url - URL to parse. Defaults to current URL
     */
    static getQueryParamVal(queryParam: string, url: string): string {
        // Escape brackets from requested param (for regex)
        queryParam = queryParam.replace(/[\[\]]/g, '\\$&');

        if (!safeRegex(`[?&]${queryParam}(=([^&#]*)|&|#|$)`)) {
            return null;
        }
        // Retrieve queryParam value using paramRegex
        const paramRegex = new RegExp(`[?&]${queryParam}(=([^&#]*)|&|#|$)`);
        const regexResults = paramRegex.exec(url);

        // If param not present, return null
        if (!regexResults) {
            return null;
        }

        // If param had no value, return empty string
        if (!regexResults[2]) {
            return ``;
        }

        // Replace win-1252 registered trademark with utf-8 version
        let requestedParamValue = regexResults[2];
        if (requestedParamValue.indexOf(`%AE`) !== -1 && requestedParamValue.indexOf(`%C2%AE`) === -1) {
            requestedParamValue = requestedParamValue.replace(`%AE`, `%C2%AE`);
        }

        // Replace a + with a space
        requestedParamValue = requestedParamValue.replace(/\+/g, ` `);

        // Return decoded value
        try {
            return decodeURIComponent(requestedParamValue);
        } catch (decodeURIComponentErr) {
            return ``;
        }
    }
}
