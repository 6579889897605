import styled from '@emotion/styled';

import {colors} from '../theme';

export const SwiperStyles = styled.div`
    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        z-index: 100;
        top: 20%;
        height: 125px;
        width: 40px;
        border: 1px solid ${colors.gray[150]};
        background-color: ${colors.white[100]};
        opacity: 0.9;
        padding: 0;
        visibility: visible;
        color: ${colors.black[100]};
    }

    .swiper-button-next {
        right: 0;

        &:after {
            font-size: 34px;
            font-family: fontawesome !important;
            content: '\\f102';
        }
    }

    .swiper-button-prev {
        left: 0;

        &:after {
            font-size: 34px;
            font-family: fontawesome !important;
            content: '\\f101';
        }
    }

    .swiper-button-disabled {
        display: none;
    }
`;
