import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';

import {AccountFinderDivision} from './AccountFinderDivision';
import {AccountFinderViewProps, filterVisible} from './AccountFinderView';
import {User} from '../../users/user.class';

interface AccountFinderParentProps extends AccountFinderViewProps {
    accountKeys: string[];
    accountOnlyMode: boolean;
    selectAccount: (accountNum: string, contactID: string, returnUrlParam?: string) => void;
    setAccountKeys: Dispatch<SetStateAction<string[]>>;
    user: User;
}

export const AccountFinderParent = ({
    account,
    accountKeys,
    accountOnlyMode,
    selectAccount,
    setAccountKeys,
    user,
}: AccountFinderParentProps) => {
    // Do not render if no accounts
    if (!account.accounts) return null;

    /**
     * Template
     */
    return (
        <>
            {[account].filter(filterVisible()).map((parent) => (
                <AccountFinderDivision
                    account={parent}
                    accountKeys={accountKeys}
                    accountOnlyMode={accountOnlyMode}
                    key={parent.account}
                    selectAccount={selectAccount}
                    setAccountKeys={setAccountKeys}
                    type="parent"
                    user={user}
                />
            ))}
            {account.accounts
                .filter((division) => division.type === `Division`)
                .filter(filterVisible())
                .map((division) => (
                    <AccountFinderDivision
                        account={division}
                        accountKeys={accountKeys}
                        accountOnlyMode={accountOnlyMode}
                        key={division.account}
                        selectAccount={selectAccount}
                        setAccountKeys={setAccountKeys}
                        user={user}
                    />
                ))}
        </>
    );
};
