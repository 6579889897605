import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AccountFinderAccountList} from './AccountFinderAccountList';
import {AccountFinderViewProps} from './AccountFinderView';
import {User} from '../../users/user.class';

interface AccountFinderDistrictProps extends AccountFinderViewProps {
    accountKeys: string[];
    accountOnlyMode: boolean;
    division?: string;
    selectAccount: (accountNum: string, contactID: string) => void;
    setAccountKeys: Dispatch<SetStateAction<string[]>>;
    user: User;
}

export const AccountFinderDistrict = ({
    account,
    accountKeys,
    accountOnlyMode,
    division,
    selectAccount,
    setAccountKeys,
    user,
}: AccountFinderDistrictProps) => {
    const isHidden = accountKeys.indexOf(division) >= 0;
    return (
        !account.hidden && (
            <div className={isHidden ? `d-none` : `d-block`}>
                {account.account && (
                    <div className="d-flex justify-content-between gray-100-bg border-top p-3">
                        <div className="d-flex align-items-center">
                            <div className="ml-3 mr-3">
                                {account.accounts.filter(({hidden}) => !hidden).length > 0 && (
                                    <FontAwesomeIcon
                                        icon={accountKeys.indexOf(account.account) >= 0 ? faAngleDown : faAngleUp}
                                        size="xl"
                                        className="pointer"
                                        onClick={() => {
                                            if (accountKeys.indexOf(account.account) === -1) {
                                                setAccountKeys([...accountKeys, account.account]);
                                            } else {
                                                setAccountKeys(accountKeys.filter((item) => item !== account.account));
                                            }
                                        }}
                                    />
                                )}
                            </div>
                            <div className="d-flex flex-column">
                                <div className="h6 font-weight-bold mb-0 text-capitalize">{account.name?.toLowerCase()}</div>
                                <div className="caption">{account.account}</div>
                            </div>
                        </div>
                        {account.hideLogin !== true && (
                            <div>
                                {user.activeAccount === account.account && <>Logged In</>}
                                {user.activeAccount !== account.account && !accountOnlyMode && (
                                    <button
                                        className="btn btn-secondary text-nowrap col-auto"
                                        onClick={() => selectAccount(account.account, account.contact)}
                                    >
                                        Log In
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {account.accounts && (
                    <AccountFinderAccountList
                        account={account}
                        accountKeys={accountKeys}
                        accountOnlyMode={accountOnlyMode}
                        selectAccount={selectAccount}
                        user={user}
                    />
                )}
            </div>
        )
    );
};
