import {FetchService} from '../fetch/fetch.service';
import {SentMessageInfo} from '../../shared/email/email.class';
import {StandardJSONResponse} from '../../shared/tools/AS.types';
import {
    ContactAddress,
    ContactUpdateAction,
    ContactUsParams,
    ContactVerifyRes,
    NewContactForm,
    SendQuoteRequestParams,
    ValidateAddressParams,
    ValidateAddressRes,
} from '../../shared/contacts/contact.class';

export class ContactsService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Sends a "Contact Us" email
     * @param contactUsParams
     */
    public contactUs(contactUsParams: ContactUsParams): Promise<SentMessageInfo> {
        return this._fetchService.post(`/api/contacts/contactUs`, contactUsParams);
    }

    /**
     * Creates contact on specified account
     * @param newContactForm
     */
    public createContact(newContactForm: NewContactForm) {
        return this._fetchService.post<StandardJSONResponse>(`/api/contacts/createContact`, newContactForm);
    }

    /**
     * Returns contact info for current user
     */
    public getContactInfo() {
        return this._fetchService.get<ContactVerifyRes>(`/api/contacts/getContactInfo`);
    }

    /**
     * Delays the reminder to the user by one day
     */
    public remindContactInfo() {
        return this._fetchService.get<ContactVerifyRes>(`/api/contacts/remindContactInfo`);
    }

    /**
     * Sends a "Quote Request" email
     * @param sendQuoteRequestParams
     */
    public sendQuoteRequest(sendQuoteRequestParams: SendQuoteRequestParams) {
        return this._fetchService.post<SentMessageInfo>(`/api/contacts/sendQuoteRequest`, sendQuoteRequestParams);
    }

    /**
     * Performs update action on provided contact
     * @param contactid - Contact to perform action on
     * @param action - Action to perform on contact
     */
    public updateContact(contactid: number, action: ContactUpdateAction) {
        return this._fetchService.post<StandardJSONResponse>(`/api/contacts/updateContact`, {action, contactid});
    }

    /**
     * Submits an update with provided contact info
     */
    public updateContactInfo(contactAddressArray?: ContactAddress[]) {
        // Build updateContactInfoParams (no address for "no changes")
        const updateContactInfoParams = {} as any;
        if (contactAddressArray && contactAddressArray.length) {
            updateContactInfoParams.address = contactAddressArray;
        }

        // Submit contact info update
        return this._fetchService.post<ContactVerifyRes>(`/api/contacts/updateContactInfo`, updateContactInfoParams);
    }

    /**
     * Performs validation on provided address
     * @param validateAddressParams
     */
    public validateAddress(validateAddressParams: ValidateAddressParams) {
        return this._fetchService.post<ValidateAddressRes>(`/api/contacts/validateAddress`, validateAddressParams);
    }
}
