import 'bootstrap';

interface PopUpOptions {
    breakPoint?: string;
    closeIcon?: boolean;
    componentAlias: string;
    dialog?: boolean;
    footer: boolean;
    header: boolean;
    title?: string;
}

const popUpComponentTemplate = require('./pop-up.component.ejs');

export class PopUpComponent {
    public $content: JQuery;
    public $footer: JQuery;
    public $titleBar: JQuery;
    public closeIcon: JQuery;
    public component: JQuery;
    public componentName = `pop-up-component`;
    public container: JQuery;
    public selector = `.modal`;
    private _closeIcon = true;
    private _componentAlias = ``;
    private _dialog = false;
    private _footer = true;
    private _header = true;
    private _onCloseHandler: () => void;
    private _title = ``;

    constructor(private _popUpOptions: PopUpOptions) {
        // Assign props from _popUpOptions
        for (const propName in this._popUpOptions) {
            if (this.hasOwnProperty(`_${propName}`)) {
                this[`_${propName}`] = this._popUpOptions[propName];
            }
        }

        // Activate PopUp
        this._onInitBase();
    }

    private _onInitBase() {
        this.container = $(`[data-component='pop-up-component']`);

        // Hide any modals that may be open
        PopUpComponent._closeAllModals();
        this._renderComponent();
    }

    /**
     * Closes the PopUpComponent
     * @private
     */
    public _close() {
        if (this._onCloseHandler) {
            this._onCloseHandler();
        }
        PopUpComponent._closeAllModals();
    }

    /**
     * Assigns handler for close() to call
     * @param onCloseHandler - Function to call on close()
     */
    public assignOnCloseHandler(onCloseHandler: () => void) {
        this._onCloseHandler = onCloseHandler;
    }

    /**
     * TBD
     */
    public closeOverlay() {
        this._close();
    }

    /**
     * Sets title after render using provided title
     * @param title - Title to set
     */
    public setTitle(title: string) {
        this.$titleBar.html(title);
    }

    /**
     * Initiate event handlers
     */
    private _bindEventHandlers() {
        $(`#${this._componentAlias}_modal`).on(`hidden.bs.modal`, () => {
            this._close();
            const $componentAliasModal = $(`#${this._componentAlias}_modal`);
            $componentAliasModal.modal(`hide`);
            $componentAliasModal.remove();
        });
    }

    /**
     * Close all open JQuery controlled modals
     * (ignore react components since they may try to close themselves later and crash if DOM element is gone)
     * @private
     */
    private static _closeAllModals() {
        const $modal = $(`.modal`);
        if ($modal.hasClass('react-modal')) {
            return;
        }
        $modal.modal(`hide`);
        $modal.remove();
        $(`.modal-backdrop`).remove();
        $(`body`).removeClass(`modal-open`).removeAttr(`style`);
    }

    /**
     * Binds component properties to jQuery selectors
     * @private
     */
    private _performElementBindingBase() {
        this.$content = this.component.find(`[data-binding='popUpContent']`);
        this.$footer = this.component.find(`[data-binding='popUpFooter']`);
        this.$titleBar = this.component.find(`[data-binding='popUpTitle']`);
        this.closeIcon = this.component.find(`[data-binding='closeIcon']`);
    }

    /**
     * Renders component and performs bindings
     * @private
     */
    private _renderComponent() {
        this.component = $(
            popUpComponentTemplate({
                closeIcon: this._closeIcon,
                componentAlias: this._componentAlias,
                footer: this._footer,
                header: this._header,
                title: this._title,
            }),
        );
        $(`[data-component='${this.componentName}']`).append(this.component);
        this._performElementBindingBase();
        this._bindEventHandlers();
        $(`#${this._componentAlias}_modal`).modal({
            show: true,
            focus: false,
        });
        if (this._dialog) {
            $(`.modal-dialog`).addClass(`is-dialog`);
        }
    }
}
