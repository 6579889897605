export const BodyScroll = (scroll: boolean) => {
    if (!document) {
        return null;
    }

    if (scroll) {
        document.body.classList.remove('tw-overflow-hidden');
        document.documentElement.classList.remove('tw-overflow-hidden');
    } else {
        document.body.classList.add('tw-overflow-hidden');
        document.documentElement.classList.add('tw-overflow-hidden');
    }
};
