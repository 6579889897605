import {FetchService} from '../fetch/fetch.service';
import {GetDeleteOrderCXML} from '../../server/punchout/punchout-session.class';

export class PunchoutService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Retrieves CXML to indicate the deletion of an order for the platform
     * @param orderTotal
     */
    public getDeleteOrderCXML(orderTotal: string) {
        return this._fetchService.post<GetDeleteOrderCXML>(`/api/punchout/getDeleteOrderCXML`, {
            orderTotal,
        });
    }
}
