import {FetchService} from '../fetch/fetch.service';
import {MRORequestParams} from '../../shared/search/search-result/search-result.class';
import {SearchSuggestions} from '../../shared/search/search-suggestions/search-suggestions.class';
import {SentMessageInfo} from '../../shared/email/email.class';

export class SearchService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Returns search suggestions for type-ahead
     * @param searchTerm - Text to provide suggestions for
     */
    public getSearchSuggestions(searchTerm: string): Promise<SearchSuggestions> {
        return this._fetchService.get<SearchSuggestions>(`/api/search/getSearchSuggestions?searchTerm=${encodeURIComponent(searchTerm)}`);
    }

    /**
     * Submit MRO request
     * @param mroRequestParams
     */
    public sendMRORequest(mroRequestParams: MRORequestParams) {
        return this._fetchService.post<SentMessageInfo>(`/api/search/sendMRORequest`, mroRequestParams);
    }
}
