import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {createRoot, Root} from 'react-dom/client';
import {PropsWithChildren} from 'react';

export class ReactClientService {
    private _components: any = {};
    private _id = 0;

    /**
     * TBD
     * @param component
     * @param props
     * @param element
     */
    appendComponent = <T>(
        component: React.FunctionComponent<T> | React.ComponentClass<T>,
        props: PropsWithChildren<T>,
        element: HTMLElement,
    ): [Root, HTMLDivElement] => {
        const newComponent = React.createElement(component, props);
        const newElement = document.createElement('div');
        const root = createRoot(newElement);
        root.render(newComponent);
        if (element) {
            element.appendChild(newElement);
        }
        return [root, newElement];
    };

    /**
     * Properties passed to components have to be serializable
     * @param component
     */
    component<T>(component: React.FunctionComponent<T> | React.ComponentClass<T>): (props: T) => string {
        return (props) => {
            const componentID = `${component.name}_${this._id}`;
            this._id = this._id + 1;
            this._components[componentID] = React.createElement(component, props);
            return `<div data-react-client='${componentID}'></div>`;
        };
    }

    /**
     * TBD
     * @param element
     */
    registerComponents = (element: HTMLElement) => {
        const elements = element.querySelectorAll<HTMLDivElement>('[data-react-client]');
        for (const el of Array.from(elements)) {
            const name = el.dataset.reactClient;
            if (this._components[name]) {
                ReactDOM.render(this._components[name], el);
            }
        }
    };
}
