import {Observable, Observer} from 'rxjs';

import {CreateOrderRes} from '../../shared/orders/order.class';
import {ErrorModal} from '../../shared/error-handling/ErrorModal';
import {gaLegacyCustomEvent} from '../ga/ga-legacy.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {OpenOrdersModal} from '../../shared/orders/open-orders/OpenOrdersModal';
import {OrdersService} from './orders.service';
import {ProgressOverlay} from '../components/ProgressOverlay';
import {ReactClientService} from '../react/react-client.service';
import {UsersService} from '../users/users.service';
import {UserStateService} from '../users/user-state.service';
import {User} from '../../shared/users/user.class';

export class OrdersWorkflow {
    private _currentUser: User = null;
    private readonly _reactClientService: ReactClientService;

    constructor(
        private _ordersService: OrdersService,
        private _usersService: UsersService,
        private _userStateService: UserStateService,
    ) {
        this._currentUser = this._usersService.getCurrentUser();
        this._reactClientService = new ReactClientService();
    }

    /**
     * Creates a new, empty order
     * @param componentName - Component performing the createOrder
     * @param onPopUpCallback
     */
    public createOrder(componentName?: string, onPopUpCallback?: () => void): Observable<CreateOrderRes> {
        return new Observable((observer: Observer<CreateOrderRes>) => {
            // Record createOrder action
            if (componentName) {
                this._userStateService.recordPendingAction(componentName, `createOrder`);
            }

            // If not logged in, showLoginPopUp
            if (!this._currentUser.isLoggedIn()) {
                this._usersService.showLoginPopUp();
                return;
            }

            // If not activeAccount, present AccountPickerComponent in select mode
            if (!this._currentUser.activeAccount) {
                this._usersService.showAccountPicker(true, onPopUpCallback);
                return;
            }

            // If division level, present AccountPickerComponent in drill mode
            if (this._currentUser.isDivisionLevel()) {
                this._usersService.showAccountPicker(false, onPopUpCallback);
                return;
            }
            // Else proceed with order creation
            this._ordersService
                .createOrder()
                .then((createOrderRes) => {
                    observer.next(createOrderRes);
                    observer.complete();
                })
                .catch((createOrderErr: ImpError) => {
                    observer.error(createOrderErr);
                    observer.complete();
                });
        });
    }

    /**
     * Creates order then navigates to it
     * @param componentName - Component performing the createOrderNavOrderDetail
     * @param onPopUpCallback
     */
    public createOrderNavOrderDetail(componentName: string, onPopUpCallback?: () => void): Observable<CreateOrderRes> {
        return new Observable((observer: Observer<CreateOrderRes>) => {
            this.createOrder(componentName, onPopUpCallback).subscribe({
                next: (createOrderRes) => {
                    this._userStateService.clearPendingAction();
                    location.assign(`/orders/${createOrderRes.ordrNbr}`);
                },
                error: (createOrderErr: ImpError) => {
                    observer.error(createOrderErr);
                    observer.complete();
                },
            });
        });
    }

    /**
     * Allows user to pick an existing order, or create a new one
     * @param componentName - Component performing the createOrPickOrder
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @returns Order number of the new or picked order
     */
    public createOrPickOrder(componentName: string, onPopUpCallback?: () => void): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            // Record createOrPickOrder action
            this._userStateService.recordPendingAction(componentName, `createOrder`);

            // If not logged in, showLoginPopUp
            if (!this._currentUser.isLoggedIn()) {
                this._usersService.showLoginPopUp();
                return;
            }

            // If currentOrderNumber return that
            if (this._ordersService.currentOrderNumber) {
                observer.next(this._ordersService.currentOrderNumber);
                observer.complete();
                return;
            }

            // If not activeAccount, present AccountPickerComponent in select mode
            if (!this._currentUser.activeAccount) {
                this._usersService.showAccountPicker(true, onPopUpCallback);
                return;
            }

            // If createNewOrderSelected, attempt to create a new order
            if (this._userStateService.createNewOrderSelected) {
                this._createOrderResolveOrderNum(observer, onPopUpCallback);

                // Else show OpenOrdersComponent
            } else {
                this.showOpenOrderPicker(onPopUpCallback).subscribe({
                    next: (showOpenOrderPickerRes) => {
                        if (showOpenOrderPickerRes === `createNewOrder`) {
                            this._createOrderResolveOrderNum(observer, onPopUpCallback);
                        } else if (showOpenOrderPickerRes) {
                            observer.next(showOpenOrderPickerRes);
                            observer.complete();
                        } else {
                            observer.next(``);
                            observer.complete();
                        }
                    },
                    error: (showOpenOrderPickerErr: ImpError) => {
                        observer.error(showOpenOrderPickerErr);
                        observer.complete();
                    },
                });
            }
        });
    }

    /**
     * Creates or picks order, then navigates to it
     * @param componentName - Component performing the createOrPickOrderNavOrderDetail
     */
    public createOrPickOrderNavOrderDetail(componentName: string) {
        this.createOrPickOrder(componentName).subscribe({
            next: (createOrPickOrderRes) => {
                // Check if user cancelled
                if (createOrPickOrderRes) {
                    this._userStateService.clearPendingAction();
                    location.assign(`/orders/${createOrPickOrderRes}`);
                }
            },
            error: (createOrPickOrderErr: ImpError) => {
                const [root] = this._reactClientService.appendComponent(
                    ErrorModal,
                    {errorMsg: createOrPickOrderErr.message, onClose: () => root.unmount(), show: true},
                    $(`#reactModalElement`)[0],
                );
            },
        });
    }

    /**
     * Presents OpenOrdersComponent to user to get input
     * @param onPopUpCallback
     * @returns Picked order number or 'createNewOrder'
     */
    public showOpenOrderPicker(onPopUpCallback?: () => void): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            this._ordersService
                .getOrders()
                .then((getOrdersRes) => {
                    const openOrders = OrdersService.getEditableOrders(getOrdersRes);
                    if (openOrders.length > 0) {
                        // Call onPopUpCallback if exists (close calling UI)
                        if (onPopUpCallback) {
                            onPopUpCallback();
                        }

                        // Present a list of open orders to choose from
                        ProgressOverlay.deactivate();
                        const [root] = this._reactClientService.appendComponent(
                            OpenOrdersModal,
                            {
                                onClose: () => root.unmount(),
                                onSelectOrder: (orderOption) => {
                                    // Cancel transaction
                                    if (!orderOption) {
                                        this._userStateService.clearPendingAction();
                                        observer.next(``);
                                        observer.complete();
                                        return;
                                    }

                                    // Create new order
                                    if (orderOption === `createNewOrder`) {
                                        this._userStateService.recordCreateNewOrderSelected();
                                        observer.next(`createNewOrder`);
                                        observer.complete();
                                        return;
                                    }

                                    // Else return order user picked
                                    observer.next(orderOption);
                                    observer.complete();
                                },
                                orderHeaders: openOrders,
                                show: true,
                                user: this._currentUser,
                            },
                            $(`#reactModalElement`)[0],
                        );
                    } else {
                        this._createOrderResolveOrderNum(observer, onPopUpCallback);
                    }
                })
                .catch((getOrdersErr: ImpError) => {
                    observer.error(getOrdersErr);
                    observer.complete();
                });
        });
    }

    /**
     * Creates a new order, returning the order numb in the provided callback
     * @param createOrderObserver - Used to pass response to createOrder caller
     * @param onPopUpCallback - Function to call if a pop-up is displayed
     * @private
     */
    private _createOrderResolveOrderNum(createOrderObserver: Observer<string>, onPopUpCallback: () => void) {
        this.createOrder(null, onPopUpCallback).subscribe({
            next: (createOrderRes) => {
                if (createOrderRes && createOrderRes.ordrNbr) {
                    gaLegacyCustomEvent({eventAction: `create_new_order`, eventCategory: `Ecommerce`, eventLabel: `cart_icon`});
                    createOrderObserver.next(createOrderRes.ordrNbr);
                } else {
                    createOrderObserver.next(`There was a problem creating your order`);
                }
                createOrderObserver.complete();
            },
            error: (createOrderErr: ImpError) => {
                createOrderObserver.error(createOrderErr);
                createOrderObserver.complete();
            },
        });
    }
}
