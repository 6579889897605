import * as React from 'react';
import {ElementType, FC, ReactNode, useRef} from 'react';
import {signal, useSignal, useSignalEffect} from '@preact/signals-react';
import {useIsomorphicLayoutEffect} from 'usehooks-ts';

import {GaPromotion, gaSelectPromotion, gaViewPromotion} from '../../../client/ga/ga-ecommerce.functions';
import {InViewport} from '../../../client/ui/components/in-viewport';

interface PromotionTrackerProps {
    as?: ElementType;
    children: ReactNode;
    classes?: string;
    detectPartial?: boolean;
    promotion: GaPromotion;
    trackImpressions?: boolean;
}

const gaPromotions = signal<GaPromotion[]>([]);

const PromotionTracker: FC<PromotionTrackerProps> = ({
    as: AsComponent = 'div',
    children,
    classes,
    detectPartial = true,
    promotion,
    trackImpressions = true,
}) => {
    const fired = useSignal(false);
    const ref = useRef(null);

    /**
     * Record promotion click
     */
    const trackClick = () => {
        gaSelectPromotion([promotion]);
    };

    /**
     * Record viewing promotions
     */
    const recordGAPromotionImpressions = () => {
        setTimeout(() => {
            if (gaPromotions.value.length > 0) {
                gaViewPromotion(gaPromotions.value);
            }
            gaPromotions.value = [];
        }, 1000);
    };

    useSignalEffect(() => {
        recordGAPromotionImpressions();
    });

    const scrollResizeListener = () => {
        if (trackImpressions) {
            if (!fired.value && ref.current && InViewport.inViewport(ref.current, detectPartial)) {
                // Send to GA
                fired.value = true;
                gaPromotions.value = [...gaPromotions.value, promotion];
            }
        }
    };

    useIsomorphicLayoutEffect(() => {
        setTimeout(() => {
            scrollResizeListener();
        }, 1000); // had to slow this call down
    });

    // Scroll & Resize
    useIsomorphicLayoutEffect(() => {
        document.addEventListener('scroll', scrollResizeListener);
        document.addEventListener('resize', scrollResizeListener);

        return () => {
            document.removeEventListener('scroll', scrollResizeListener);
            document.removeEventListener('resize', scrollResizeListener);
        };
    });

    return (
        <AsComponent
            className={classes}
            onClick={trackClick}
            ref={ref}
        >
            {children}
        </AsComponent>
    );
};

export default PromotionTracker;
