import {FetchService} from '../fetch/fetch.service';
import {gaLegacyCustomEvent} from '../ga/ga-legacy.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {Lists} from '../../shared/lists/lists/lists.class';
import {ShareLinkParams} from '../../shared/share-link/share-link.class';
import {
    IsDefaultListTypes,
    List,
    ListType,
    ReplaceListItemClientParams,
    ShareListRes,
    UpdateItemsOnListActionTypes,
    UpdateItemsOnListRes,
    UpdateListActionTypes,
    UpdateListItem,
    UpdateListRes,
} from '../../shared/lists/list.class';

export class ListsService {
    public currentListId: string;

    constructor(private _fetchService: FetchService) {}

    /**
     * Emails PDF copy of list
     * @param listId - ListId to email
     */
    public emailList(listId: string) {
        return new Promise<List>((resolve, reject) => {
            this._fetchService
                .get<List>(`/api/lists/email/${listId}`)
                .then((emailListRes) => {
                    gaLegacyCustomEvent({eventAction: `email`, eventCategory: `lists`, eventLabel: listId});
                    resolve(emailListRes);
                })
                .catch((emailListErr: ImpError) => {
                    reject(emailListErr);
                });
        });
    }

    /**
     * Gets specified list
     * @param listId - ListId to retrieve
     * @param numParam - Additional num to use with listId
     */
    public getList(listId: string, numParam?: string) {
        return this._fetchService.get<List>(`/api/lists/${listId}?numParam=${numParam || ``}`, {cache: `no-store`});
    }

    /**
     * Gets specified list
     * @param listId - ListId to retrieve
     * @param tokenId - Share List Token ID
     */
    public getListFromToken(listId: string, tokenId: string) {
        return this._fetchService.get<List>(`/api/lists/${listId}?tokenId=${tokenId}`, {cache: `no-store`});
    }

    /**
     * Retrieves lists user has access to
     */
    public getLists() {
        return this._fetchService.get<Lists>(`/api/lists`, {cache: `no-store`});
    }

    /**
     * Replaces old list item with new list item
     * @param replaceListItemParams
     */
    public replaceListItem(replaceListItemParams: ReplaceListItemClientParams) {
        return this._fetchService.post<UpdateItemsOnListRes>(`/api/list/replaceListItem`, replaceListItemParams);
    }

    /**
     * Shares list
     * @param shareListParams
     */
    public shareList(shareListParams: ShareLinkParams) {
        return this._fetchService.post<ShareListRes>(`/api/list/shareList`, shareListParams);
    }

    /**
     * Updates the items on the specified list
     * @param action
     * @param updateListItems - array of actions to perform
     * @param listId - ListID for items
     */
    public updateItemsOnList(action: UpdateItemsOnListActionTypes, updateListItems: UpdateListItem[], listId: string) {
        return this._fetchService.post<UpdateItemsOnListRes>(`/api/lists/updateItemsOnList`, {action, updateListItems, listId});
    }

    /**
     * Updates specified list
     * @param listId - List to update
     * @param action - Action to perform on list
     * @param listItem
     * @param isDefault - If new list should be default
     * @param roQty - reorder qty
     * @param comment - special comment
     * @param listName - Name to use for the list
     * @param listType - If new list, list type to use
     * @param tokenId - If shared list
     */
    public updateList(
        listId: string,
        action: UpdateListActionTypes,
        listItem?: string,
        isDefault?: IsDefaultListTypes,
        listName?: string,
        listType?: ListType,
        tokenId?: string,
        roQty?: number,
        comment?: string,
    ) {
        return this._fetchService.post<UpdateListRes>(`/api/lists/${listId}/${action}`, {
            isDefault: isDefault || `no`,
            listName: listName || ``,
            listItem: listItem || ``,
            listType: listType || ``,
            roQty: roQty || null,
            comment: comment || ``,
            tokenId,
            items: [
                {
                    item: listItem,
                    qty: roQty || Number(null),
                    comment: comment || ``,
                },
            ],
        });
    }
}
