import {useEffect, useState} from 'react';

import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ImpError} from '../../../client/imp-error/imp-error.class';
import {ItemToAdd} from '../../order-items/order-items.class';
import {OrderItemsWorkflow} from '../../../client/order-items/order-items.workflow';
import {OrdersService} from '../../../client/orders/orders.service';
import {OrdersWorkflow} from '../../../client/orders/orders.workflow';
import {Order} from '../../orders/order.class';
import {ProgressOverlay} from '../../../client/components/ProgressOverlay';
import {SavedForLaterItem} from '../../orders/saved-for-later/saved-for-later-item.class';
import {useService} from '../../react/ServiceContext';

interface UseOrdersProps {
    componentName: string;
    order?: Order;
    savedForLaterItems?: SavedForLaterItem[];
}

export const useOrder = ({componentName, order: _order, savedForLaterItems: _savedForLaterItems}: UseOrdersProps) => {
    const [order, setOrder] = useState(_order);
    const [orderErrors, setOrderErrors] = useState<ImpError[]>([]);
    const [savedForLaterItemsState, setSavedForLaterItemsState] = useState<SavedForLaterItem[]>(_savedForLaterItems);
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const ordersService: OrdersService = useService(`ordersService`);
    const ordersWorkflow: OrdersWorkflow = useService(`ordersWorkflow`);

    // Configure integration with currentOrder$
    useEffect(() => {
        // Set current order for shared state if provided
        if (_order) {
            ordersService.setCurrentOrder(_order);
        }

        // Subscribe to updates
        ordersService.currentOrder$.subscribe({
            next: (currentOrder) => {
                if (currentOrder) setOrder(currentOrder);
            },
        });
    }, []);

    // Subscribe to savedForLaterItems$
    useEffect(() => {
        if (_savedForLaterItems) {
            ordersService.savedForLaterItems$.subscribe({
                next: (savedForLaterItems) => {
                    setSavedForLaterItemsState(savedForLaterItems);
                },
            });
        }
    }, []);

    /**
     * Adds item to order and removes from saved for later items
     * @param itemNum
     * @param unitsOrdered
     */
    const addSavedForLaterItemToOrder = (itemNum: string, unitsOrdered: number) => {
        const itemToAdd: ItemToAdd = {
            item: itemNum,
            list: `save for later`,
            unitsOrdered,
        };
        setOrderErrors(null);
        gaLegacyCustomEvent({eventAction: `Add to Order Clicks`, eventCategory: `Ecommerce`, eventLabel: componentName});
        orderItemsWorkflow.addToOrderToast([itemToAdd], componentName, true, `Item Moved to Order`).subscribe({
            next: () => {
                ordersService.updateSavedForLaterItems(itemNum, `remove`).catch((updateSavedForLaterItemsErr: ImpError) => {
                    setOrderErrors([updateSavedForLaterItemsErr]);
                });
            },
            error: (addToOrderToastErr: ImpError) => {
                setOrderErrors([addToOrderToastErr]);
            },
        });
    };

    /**
     * Deletes saved for later item
     * @param itemNum - Item to delete
     */
    const deleteSavedForLaterItem = (itemNum: string) => {
        setOrderErrors(null);
        gaLegacyCustomEvent({eventAction: `click`, eventCategory: `Ecommerce`, eventLabel: `remove save for later`});
        ordersService.updateSavedForLaterItems(itemNum, `remove`).catch((updateSavedForLaterItemsErr: ImpError) => {
            setOrderErrors([updateSavedForLaterItemsErr]);
        });
    };

    /**
     * Creates a new order
     */
    const createNewOrder = () => {
        gaLegacyCustomEvent({eventAction: `create_new_order`, eventCategory: `Ecommerce`, eventLabel: componentName});
        ordersWorkflow.createOrderNavOrderDetail(componentName).subscribe({
            error: (createOrderErr: ImpError) => {
                alert(createOrderErr.message);
            },
        });
    };

    /**
     * Deletes an order
     * @param orderNumber
     */
    const deleteBackOrder = (orderNumber: string) => {
        ordersService
            .deleteBackorder(orderNumber)
            .then(() => {
                ProgressOverlay.deactivate();
                location.assign(`/orders?deletedOrder=${orderNumber}`);
            })
            .catch((deleteFromOrderErr: ImpError) => {
                ProgressOverlay.deactivate();
                setOrderErrors([deleteFromOrderErr]);
            });
    };

    /**
     * TBD
     * @param onComplete
     */
    const getOrders = (onComplete) => {
        ordersService
            .getOrders()
            .then((getOrdersRes) => {
                onComplete(getOrdersRes);
            })
            .catch((getOrdersErr: ImpError) => {
                setOrderErrors([getOrdersErr]);
            });
    };

    /**
     * Creates new order from provided items
     * @param splitItems - Item to delete
     */
    const splitOrder = (splitItems: string[]) => {
        ProgressOverlay.activate();
        ordersService
            .splitOrder(order.orderNbr, splitItems)
            .then((splitOrderRes) => {
                location.assign(`/orders/${splitOrderRes.newOrder}/edit`);
            })
            .catch((splitOrderErr) => {
                ProgressOverlay.deactivate();
                setOrderErrors([splitOrderErr]);
            });
    };

    // Return properties
    return {
        addSavedForLaterItemToOrder,
        createNewOrder,
        deleteBackOrder,
        deleteSavedForLaterItem,
        getOrders,
        order,
        orderErrors,
        savedForLaterItemsState,
        splitOrder,
    };
};
