import {Observable, Observer} from 'rxjs';

import {ErrorModal} from '../../shared/error-handling/ErrorModal';
import {gaLegacyCustomEvent} from '../ga/ga-legacy.functions';
import {ImpError} from '../imp-error/imp-error.class';
import {ListsService} from './lists.service';
import {PickListWorkflow} from '../../shared/lists/PickListWorkflow';
import {ProgressOverlay} from '../components/ProgressOverlay';
import {ReactClientService} from '../react/react-client.service';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {UsersService} from '../users/users.service';
import {UserStateService} from '../users/user-state.service';
import {User} from '../../shared/users/user.class';
import {
    ListPickerRes,
    UpdateItemsOnListActionTypes,
    UpdateItemsOnListItem,
    UpdateItemsOnListRes,
    UpdateListItem,
} from '../../shared/lists/list.class';

export class ListsWorkflow {
    private _currentUser: User;
    private readonly _reactClientService: ReactClientService;

    constructor(
        private _listsService: ListsService,
        private _usersService: UsersService,
        private _userStateService: UserStateService,
    ) {
        this._currentUser = this._usersService.getCurrentUser();
        this._reactClientService = new ReactClientService();
    }

    /**
     * Adds provided items to a list
     * @param action
     * @param addToListItems - Array of items to add
     * @param componentName - Component performing the addToList
     * @param gaReference - Identity to pass to GA
     * @param itemDesc - Description to use in pop up (if one item)
     * @param itemImage - Image ot use in pop up (if one item)
     * @param onPopUpCallback - Method to call if presenting a pop up
     * @param observer - Handler for success and failure
     */
    public addToList(
        action: UpdateItemsOnListActionTypes,
        addToListItems: UpdateListItem[],
        componentName: string,
        gaReference: string,
        itemDesc: string,
        itemImage: string,
        onPopUpCallback?: () => void,
        observer?: Observer<UpdateItemsOnListRes>,
    ) {
        // Record addToList action
        this._userStateService.recordAddToListAction(componentName, addToListItems, itemDesc, itemImage);

        // If not logged in, showLoginPopUp
        if (!this._currentUser.isLoggedIn()) {
            this._usersService.showLoginPopUp(onPopUpCallback);
            return;
        }

        // If not activeAccount, present AccountPickerComponent in select mode
        if (!this._currentUser.activeAccount) {
            this._usersService.showAccountPicker(true, onPopUpCallback);
            return;
        }

        // Present AddToListComponent
        this._showListPicker(itemDesc, itemImage).subscribe({
            next: (showListPickerRes) => {
                ProgressOverlay.activate();

                // Handle create a new "My Favorites
                if (showListPickerRes.listId === `newDefaultList`) {
                    this._listsService
                        .updateList(`newList`, `add`, '', `yes`, ``)
                        .then((updateListRes) => {
                            const newListId = updateListRes.ezid ? updateListRes.ezid.toString() : ``;
                            this._updateItemsOnListWorkflow(
                                action,
                                addToListItems,
                                gaReference,
                                newListId,
                                showListPickerRes.listName,
                                observer,
                            );
                        })
                        .catch((updateListErr: ImpError) => {
                            ProgressOverlay.deactivate();
                            if (observer) {
                                observer.error(updateListErr);
                                observer.complete();
                            } else {
                                const [root] = this._reactClientService.appendComponent(
                                    ErrorModal,
                                    {errorMsg: updateListErr.message, onClose: () => root.unmount(), show: true},
                                    $(`#reactModalElement`)[0],
                                );
                            }
                        });

                    // Process with listId that user picked or created
                } else if (showListPickerRes.listId) {
                    this._updateItemsOnListWorkflow(
                        action,
                        addToListItems,
                        gaReference,
                        showListPickerRes.listId,
                        showListPickerRes.listName,
                        observer,
                    );

                    // User cancelled addToList
                } else {
                    ProgressOverlay.deactivate();
                }
            },
            error: (showListPickerErr: ImpError) => {
                if (observer) {
                    observer.error(showListPickerErr);
                    observer.complete();
                } else {
                    const [root] = this._reactClientService.appendComponent(
                        ErrorModal,
                        {errorMsg: showListPickerErr.message, onClose: () => root.unmount(), show: true},
                        $(`#reactModalElement`)[0],
                    );
                }
            },
        });
    }

    /**
     * Adds provided items to a list, using an observable
     * @param action
     * @param addToListItems - Array of items to add
     * @param componentName - Component performing the addToList
     * @param gaReference - Identity to pass to GA
     * @param itemDesc - Description to use in pop up (if one item)
     * @param itemImage - Image ot use in pop up (if one item)
     * @param onPopUpCallback - Method to call if presenting a pop up
     */
    public addToListObservable(
        action: UpdateItemsOnListActionTypes,
        addToListItems: UpdateListItem[],
        componentName: string,
        gaReference: string,
        itemDesc: string,
        itemImage: string,
        onPopUpCallback?: () => void,
    ): Observable<UpdateItemsOnListRes> {
        return new Observable((observer: Observer<UpdateItemsOnListRes>) => {
            this.addToList(action, addToListItems, componentName, gaReference, itemDesc, itemImage, onPopUpCallback, observer);
        });
    }

    /**
     * Updates the items on the specified list
     * @param action
     * @param updateListItems - array of actions to perform
     * @param listId - ListID for items
     */
    public updateItemsOnListWorkflow(
        action: UpdateItemsOnListActionTypes,
        updateListItems: UpdateListItem[],
        listId: string,
    ): Observable<UpdateItemsOnListRes> {
        return new Observable((observer: Observer<UpdateItemsOnListRes>) => {
            this._listsService
                .updateItemsOnList(action, updateListItems, listId)
                .then((updateItemsOnListRes) => {
                    // Determine successes and errors
                    const updateItemOnListSuccessArray: UpdateItemsOnListItem[] = [];
                    const updateItemOnListErrorArray: UpdateItemsOnListItem[] = [];
                    for (const updateListItem of updateItemsOnListRes.items) {
                        if (updateListItem.result === `OK`) {
                            updateItemOnListSuccessArray.push(updateListItem);
                        } else {
                            updateItemOnListErrorArray.push(updateListItem);
                        }
                    }

                    // Display snackbar for successes
                    if (updateItemOnListSuccessArray.length) {
                        const snackbarMessage = `Item${updateItemOnListSuccessArray.length > 1 ? `s` : ``} ${
                            action === `delete` ? `Removed From` : `Added To`
                        } List`;
                        new SnackbarComponent(snackbarMessage);
                    }

                    // Build errorMessage for failures
                    let errorMessage = `You've encountered the following errors:\r\n`;
                    if (updateItemOnListErrorArray.length) {
                        for (const updateItemsOnListError of updateItemOnListErrorArray) {
                            if (updateItemsOnListError.error === `Invalid item`) {
                                errorMessage += `${updateItemsOnListError.error} #${updateItemsOnListError.item}\r\n`;
                            } else {
                                errorMessage += `${updateItemsOnListError.error}\r\n`;
                            }
                        }
                    }

                    // Treat 100% errors as an error
                    if (!updateItemOnListSuccessArray.length && updateItemOnListErrorArray.length) {
                        observer.error(new Error(errorMessage));
                    } else {
                        // Record currentListId for future add to list
                        this._listsService.currentListId = listId;

                        // Return response
                        observer.next(updateItemsOnListRes);
                    }

                    // Complete observer
                    observer.complete();
                })
                .catch((updateItemsOnListErr: ImpError) => {
                    observer.error(updateItemsOnListErr);
                    observer.complete();
                });
        });
    }

    /**
     * Presents AddToListComponent
     * @param itemDesc
     * @param itemImage
     */
    private _showListPicker(itemDesc: string, itemImage: string): Observable<ListPickerRes> {
        return new Observable((observer: Observer<ListPickerRes>) => {
            this._listsService
                .getLists()
                .then((getListsRes) => {
                    const [root] = this._reactClientService.appendComponent(
                        PickListWorkflow,
                        {
                            getListId: (getListIdRes) => {
                                observer.next(getListIdRes);
                                observer.complete();
                            },
                            itemDesc,
                            itemImage,
                            onClose: () => {
                                this._userStateService.clearPendingAction();
                                root.unmount();
                            },
                            show: true,
                            userLists: getListsRes.userLists,
                        },
                        $(`#reactModalElement`)[0],
                    );
                })
                .catch((getListsErr: ImpError) => {
                    observer.error(getListsErr);
                    observer.complete();
                });
        });
    }

    /**
     * TBD
     * @param action
     * @param addToListItems
     * @param gaReference
     * @param listId
     * @param listName
     * @param observer
     * @private
     */
    private _updateItemsOnListWorkflow(
        action: UpdateItemsOnListActionTypes,
        addToListItems: UpdateListItem[],
        gaReference: string,
        listId: string,
        listName: string,
        observer: Observer<UpdateItemsOnListRes>,
    ) {
        this.updateItemsOnListWorkflow(action, addToListItems, listId).subscribe({
            next: (updateItemsOnListRes) => {
                ProgressOverlay.deactivate();
                gaLegacyCustomEvent({eventAction: `${gaReference} add` as any, eventCategory: `lists`, eventLabel: listName});
                if (observer) {
                    observer.next(updateItemsOnListRes);
                    observer.complete();
                }
            },
            error: (updateItemsOnListErr: ImpError) => {
                ProgressOverlay.deactivate();
                if (observer) {
                    observer.error(updateItemsOnListErr);
                    observer.complete();
                } else {
                    const [root] = this._reactClientService.appendComponent(
                        ErrorModal,
                        {errorMsg: updateItemsOnListErr.message, onClose: () => root.unmount(), show: true},
                        $(`#reactModalElement`)[0],
                    );
                }
            },
        });
    }
}
