module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
"use strict";
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div\naria-hidden=\"true\"\naria-labelledby=\"<%=locals.title%>\"\nclass=\"modal modal-fullscreen-sm p-0\"\nid=\"<%=locals.componentAlias%>_modal\"\nrole=\"dialog\"\ntabindex=\"-1\"\n>\n<div\nclass=\"modal-dialog modal-dialog-scrollable modal-dialog-centered\"\nrole=\"document\"\n>\n<div class=\"modal-content\">\n<%if (locals.header) {%>\n<div\nclass=\"modal-header d-flex align-items-center gray-600-bg text-white py-2\"\nid=\"puTittleText\"\n>\n<h3\nclass=\"modal-title\"\ndata-binding=\"popUpTitle\"\nid=\"exampleModalLongTitle\"\n>\n<%if (locals.title) {%>\n<%=locals.title%>\n<%}%>\n</h3>\n<button\naria-label=\"Close\"\nclass=\"close btn btn-link text-decoration-none\"\ndata-binding=\"closeIcon\"\ndata-dismiss=\"modal\"\n>\n<em class=\"fa fa-times fa-3x gray-150\"></em>\n</button>\n</div>\n<%}%>\n<div\nclass=\"modal-body\"\ndata-binding=\"popUpContent\"\n>\n<div class=\"text-center pt-3\">\n<span class=\"spinner\"></span>\n</div>\n</div>\n<div\nclass=\"modal-footer <%=(!locals.footer ? 'd-none' : 'd-block')%> border-top-0\"\ndata-binding=\"popUpFooter\"\n>\n</div>\n</div>\n</div>\n</div>"
  , __filename = "src\\client\\ui\\pop-up\\pop-up.component.ejs";
try {
  var __output = [], __append = __output.push.bind(__output);
    ; __append("<div\naria-hidden=\"true\"\naria-labelledby=\"")
    ; __line = 3
    ; __append(escapeFn(locals.title))
    ; __append("\"\nclass=\"modal modal-fullscreen-sm p-0\"\nid=\"")
    ; __line = 5
    ; __append(escapeFn(locals.componentAlias))
    ; __append("_modal\"\nrole=\"dialog\"\ntabindex=\"-1\"\n>\n<div\nclass=\"modal-dialog modal-dialog-scrollable modal-dialog-centered\"\nrole=\"document\"\n>\n<div class=\"modal-content\">\n")
    ; __line = 14
    ; if (locals.header) {
    ; __append("\n<div\nclass=\"modal-header d-flex align-items-center gray-600-bg text-white py-2\"\nid=\"puTittleText\"\n>\n<h3\nclass=\"modal-title\"\ndata-binding=\"popUpTitle\"\nid=\"exampleModalLongTitle\"\n>\n")
    ; __line = 24
    ; if (locals.title) {
    ; __append("\n")
    ; __line = 25
    ; __append(escapeFn(locals.title))
    ; __append("\n")
    ; __line = 26
    ; }
    ; __append("\n</h3>\n<button\naria-label=\"Close\"\nclass=\"close btn btn-link text-decoration-none\"\ndata-binding=\"closeIcon\"\ndata-dismiss=\"modal\"\n>\n<em class=\"fa fa-times fa-3x gray-150\"></em>\n</button>\n</div>\n")
    ; __line = 37
    ; }
    ; __append("\n<div\nclass=\"modal-body\"\ndata-binding=\"popUpContent\"\n>\n<div class=\"text-center pt-3\">\n<span class=\"spinner\"></span>\n</div>\n</div>\n<div\nclass=\"modal-footer ")
    ; __line = 47
    ; __append(escapeFn((!locals.footer ? 'd-none' : 'd-block')))
    ; __append(" border-top-0\"\ndata-binding=\"popUpFooter\"\n>\n</div>\n</div>\n</div>\n</div>")
    ; __line = 53
  return __output.join("");
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}