import {FetchService} from '../fetch/fetch.service';
import {SubscribeMonthlyPromosParams} from '../../shared/net-results/net-results.interface';

export class NetResultsService {
    constructor(private _fetchService: FetchService) {}

    /**
     * Subscribes user to monthly promotions
     * @param subscribeMonthlyPromosParams
     */
    public subscribeMonthlyPromos(subscribeMonthlyPromosParams: SubscribeMonthlyPromosParams) {
        return this._fetchService.post<string>(`/api/net-results/subscribeMonthlyPromos`, subscribeMonthlyPromosParams, true);
    }
}
