import Bugsnag from '@bugsnag/js';
import {getCLS, getFID, getLCP} from 'web-vitals';

import {AuthenticationService} from './authentication/authentication.service';
import {AutoReordersService} from './auto-reorders/auto-reorders.service';
import {CmsService} from './cms/cms.service';
import {ContactInfoModal} from '../shared/contacts/contact-info-modal/ContactInfoModal';
import {ContactInfoSuccessModal} from '../shared/contacts/contact-info-success-modal/ContactInfoSuccessModal';
import {ContactsService} from './contacts/contacts.service';
import {CookiesService} from './cookies/cookies.service';
import {CreditCardsService} from './credit-cards/credit-cards.service';
import {DealsService} from './deals/deals.service';
import {Debounce} from '../shared/tools/debounce';
import {FetchService} from './fetch/fetch.service';
import {FocusedFooter} from '../shared/footer/focused-footer/FocusedFooter';
import {FocusedHeader} from '../shared/header/focused-header/FocusedHeader';
import {Footer} from '../shared/footer/site-footer/Footer';
import {gaLegacyCustomEvent, gaLegacyDataLayerPush} from './ga/ga-legacy.functions';
import {GaPromotion, gaSelectPromotion, gaViewPromotion} from './ga/ga-ecommerce.functions';
import {initContext} from '../shared/react/ServiceContext';
import {InViewport} from './ui/components/in-viewport';
import {ItemsService} from './items/items.service';
import {JobCostingService} from './job-costing/job-costing.service';
import {ListsService} from './lists/lists.service';
import {ListsWorkflow} from './lists/lists.workflow';
import {LocalStorageService} from './local-storage/local-storage.service';
import {LoginMethod, pushGaEvent} from './ga/ga.functions';
import {MonetateService} from './monetate/monetate.service';
import {NetResultsService} from './net-results/net-results.service';
import {optedOutOfTracking} from '../shared/contacts/contact.functions';
import {OrderItemsService} from './order-items/order-items.service';
import {OrderItemsWorkflow} from './order-items/order-items.workflow';
import {OrdersService} from './orders/orders.service';
import {OrdersWorkflow} from './orders/orders.workflow';
import {PopUpComponent} from './ui/pop-up/pop-up.component';
import {PromosService} from './promos/promos.service';
import {PunchoutService} from './punchout/punchout.service';
import {ReactClientService} from './react/react-client.service';
import {registerComponent} from '../shared/react/registrar';
import {RibbonAdBanner} from '../shared/ads/ribbon-ad-banner/RibbonAdBanner';
import {ScannerWorkflow} from './scanner/scanner.workflow';
import {SearchService} from './search/search.service';
import {SessionStorageService} from './session-storage/session-storage.service';
import {SignalsService} from './search/signals/signals.service';
import {SiteHeader} from '../shared/header/site-header/SiteHeader';
import {TrackingService} from './tracking/tracking.service';
import {UserAgent} from './user-agent/user-agent.class';
import {UsersService} from './users/users.service';
import {UserStateService} from './users/user-state.service';
import {User} from '../shared/users/user.class';

import './../../src/tailwind.scss';

declare const debugLocals: any;
export const ESC_KEY = 27;

window.jQuery = window.$ = require('jquery');
const reactModalElement = $(`#reactModalElement`)[0];

export class AppComponent {
    public breadCrumbs: JQuery;
    public clearText: JQuery;
    public collapsible: JQuery;
    public component: JQuery;
    public csrfToken: string;
    public currentUser: User;
    public filterInput: JQuery;
    public headerMessaging: JQuery;
    public overlayOpen = false;
    public promoFiredArray = [];
    public promotions: JQuery;
    public returnToTop: JQuery;
    public showHideDebugBar: JQuery;
    public showLocals: JQuery;
    public siteFooter: JQuery;
    public siteFooterLinks: JQuery;
    public siteHeaderLinks: JQuery;
    public startChatLink: JQuery;
    public startFeedbackLink: JQuery;
    public triggerDebugBar: JQuery;
    private _debugComponent: JQuery;
    private _reactClientService: ReactClientService;

    constructor(
        public authenticationService: AuthenticationService,
        public autoReordersService: AutoReordersService,
        public cmsService: CmsService,
        public contactsService: ContactsService,
        public cookiesService: CookiesService,
        public creditCardsService: CreditCardsService,
        public dealsService: DealsService,
        public fetchService: FetchService,
        public itemsService: ItemsService,
        public jobCostingService: JobCostingService,
        public listsService: ListsService,
        public listsWorkflow: ListsWorkflow,
        public localStorageService: LocalStorageService,
        public monetateService: MonetateService,
        public netResultsService: NetResultsService,
        public orderItemsService: OrderItemsService,
        public orderItemsWorkflow: OrderItemsWorkflow,
        public ordersService: OrdersService,
        public ordersWorkflow: OrdersWorkflow,
        public promosService: PromosService,
        public punchoutService: PunchoutService,
        public scannerWorkflow: ScannerWorkflow,
        public searchService: SearchService,
        public sessionStorageService: SessionStorageService,
        public signalsService: SignalsService,
        public trackingService: TrackingService,
        public usersService: UsersService,
        public userStateService: UserStateService,
    ) {
        this.component = $(`[data-component='app-component']`);
        this._debugComponent = $(`[data-component='debug-console']`);

        AppComponent.setBodyClass();
        this._reactClientService = new ReactClientService();

        // Bootstrap currentUser
        this.currentUser = this.usersService.getCurrentUser();
    }

    public init() {
        this._initCSRF();

        // Instantiate global components
        registerComponent(FocusedFooter, `FocusedFooter`);
        registerComponent(FocusedHeader, `FocusedHeader`);
        registerComponent(Footer, `Footer`);
        registerComponent(RibbonAdBanner, `RibbonAdBanner`);
        registerComponent(SiteHeader, `SiteHeader`);

        // Initialize common behaviors
        this._onInit();
    }

    private _onInit() {
        this._performElementBinding();
        this._registerEventHandlers();
        this._registerAppHandlers();
        this._verifyContactInfo();

        // Initialize components with AppComponent binding dependencies
        this._trackWebVitals();
        this._clearTrackingCookies();
    }

    /**
     * Set a class on Body if fullscreen
     * @private
     */
    private static setBodyClass() {
        if (UserAgent.isNativeApp() || UserAgent.isFullScreen()) {
            $('body').addClass('isFullscreen');
        }
    }

    /**
     * Hides transparent full screen overlay
     */
    public hideOverlay() {
        const overlay = document.getElementById(`divOverlay`);
        overlay.style.display = `none`;
        $(`body`).css(`overflow`, `auto`);
        this.overlayOpen = false;
    }

    /**
     * Opens scanner popup after lazy loading it
     * @param gaAction
     */
    public openScanner(gaAction: `hamburger_menu` | `header` | `scanner_reload`) {
        gaLegacyCustomEvent({eventAction: gaAction, eventCategory: `barcode_scanner`});
        import('./scanner/scanner.logic.factory')
            .then((scanLib) => {
                const scannerLogic = scanLib.createScannerLogic(
                    this.currentUser,
                    this.itemsService,
                    this.jobCostingService,
                    this.localStorageService,
                    this.orderItemsService,
                    this.orderItemsWorkflow,
                    this.ordersService,
                    this.ordersWorkflow,
                    this.userStateService,
                );
                scannerLogic.activateScanner();
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            });
    }

    /**
     * Displays gray transparent full screen overlay
     */
    public showOverlay() {
        const overlay = document.getElementById(`divOverlay`);
        overlay.style.display = `block`;
        this.overlayOpen = true;
        $(`body`).css(`overflow`, `hidden`);
    }

    /**
     * Initializes bugsnag
     * @public
     */
    public static _initBugsnag() {
        if (impGlobal.reportBugSnag) {
            const BSClient = Bugsnag.start({
                apiKey: impGlobal.bugSnagKeyClient,
                appVersion: impGlobal.version,
                releaseStage: impGlobal.environment,
            });

            const sessionID = document.querySelector('meta[name="userSessionID"]')?.getAttribute('content');
            if (sessionID) {
                BSClient.addMetadata('session', {sessionID});
            }
        }
    }

    /**
     * TBD
     * @private
     */
    private _initCSRF() {
        const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
        if (token) {
            this.csrfToken = token;
        }
    }

    /**
     * Binds component properties to jQuery selectors
     * @private
     */
    private _performElementBinding() {
        this.breadCrumbs = this.component.find(`[data-binding='breadCrumbs']`);
        this.clearText = $(`[data-binding='clearText']`);
        this.collapsible = $(`[data-binding='collapsible']`);
        this.filterInput = $(`[aria-label='filter']`);
        this.headerMessaging = this.component.find(`[data-binding='headerMessaging']`);
        this.promotions = $(`[data-promotion]`);
        this.returnToTop = this.component.find(`[data-binding='returnToTop']`);
        this.siteFooter = this.component.find(`[data-binding='siteFooter']`);
        this.siteFooterLinks = this.component.find(`footer a`);
        this.siteHeaderLinks = $(`header a`);
        this.startChatLink = this.component.find(`[data-binding='startChatLink']`);
        this.startFeedbackLink = this.component.find(`[data-binding='startFeedbackLink']`);

        // Debug bindings
        this.showHideDebugBar = this._debugComponent.find(`[data-binding='showHideDebugBar']`);
        this.showLocals = this._debugComponent.find(`[data-binding='showCurrentLocals']`);
        this.triggerDebugBar = this._debugComponent.find(`[data-binding='triggerDebugBar']`);
    }

    /**
     * Pushes loginMethod and user_id to dataLayer for analytics
     * @param gaLoginMethod - Method user logged in with
     * @private
     */
    private _recordGaLogin(gaLoginMethod: LoginMethod) {
        if (gaLoginMethod && this.currentUser.isLoggedIn()) {
            pushGaEvent(`ga4_login`, {login_method: gaLoginMethod, user_id: this.currentUser.UIDHash});
            this.sessionStorageService.removeItem(`recordGaLogin`);
        }
    }

    /**
     * Registers event handlers for react native if needed
     * @private
     */
    private _registerAppHandlers() {
        // Confirm React Native
        if (!UserAgent.isReactNative()) {
            return;
        }

        // Open external links in new window
        document.addEventListener(`click`, (e) => {
            const originLink = (e.target as any)?.closest(`a`);

            if (!originLink) {
                return true;
            }
            if (originLink.target === '_blank' && originLink.href) {
                e.stopPropagation();
                e.preventDefault();
                UserAgent.postNativeMessage({
                    data: originLink.href,
                    event: `externalLink`,
                });
                return false;
            }
        });

        // Send currentUser info
        UserAgent.postNativeMessage({
            event: `currentUser`,
            user: this.currentUser,
        });

        // Turn off offline mode on page load
        UserAgent.postNativeMessage({data: `false`, event: `setOfflineMode`});
    }

    /**
     * Registers event handlers on component properties
     * @private
     */
    private _registerEventHandlers() {
        if (this.clearText) {
            this.clearText.hide();
            this.clearText.on(`click`, () => {
                this.clearText.parents(`.input-group`).find(`input`).val('').keyup();
                this.clearText.hide();
            });
        }
        if (this.filterInput) {
            this.filterInput.on(`keyup`, () => {
                if (this.filterInput.val().length > 2) {
                    this.clearText.show();
                }
            });
        }
        this.promotions.on(`click`, {component: this}, AppComponent._sendAnalytics);
        this.triggerDebugBar.on(`click`, () => {
            this.showHideDebugBar.slideToggle();
        });
        this.startChatLink.on(`click`, AppComponent.showChatWindow);
        this.startFeedbackLink.on(`click`, AppComponent.showFeedbackWindow);
        this.showLocals.on(`click`, () => {
            const popUp = new PopUpComponent({
                closeIcon: true,
                componentAlias: `debug-component`,
                footer: false,
                header: true,
                title: `Displaying Locals`,
            });
            popUp.$content.html(`<textarea rows="20" style="width:99%;">${JSON.stringify(debugLocals, null, '\t')}</textarea>`);
        });
        this.siteHeaderLinks.on('click', () => {
            this.localStorageService.removeItem(`searchTerm`);
        });
        this.siteFooterLinks.on('click', () => {
            this.localStorageService.removeItem(`searchTerm`);
        });

        $(window).on(`load`, () => {
            const loadScanner = this.localStorageService.getItem(`loadScanner`);
            if (loadScanner) {
                this.localStorageService.removeItem(`loadScanner`);
                this.openScanner(`scanner_reload`);
            }
        });

        $(window).on(
            `load resize scroll`,
            Debounce(() => {
                if ($(window).scrollTop() >= 222) {
                    this.returnToTop.fadeIn(200); // Fade in the arrow
                } else {
                    this.returnToTop.fadeOut(200); // Else fade out the arrow
                }
                this._trackPromoImpressions();
            }, 300),
        );

        this.returnToTop.click(() => {
            gaLegacyCustomEvent({eventAction: `click`, eventCategory: `back to top`, eventLabel: window.location.href});
            $(`body, html`).animate(
                {
                    scrollTop: 0, // Scroll to top of body
                },
                500,
            );
        });
        $(document).on(`input paste change`, `input[inputmode='numeric']`, function () {
            this.value = this.value.replace(/[^0-9.]/g, ``);
        });
        $(`[data-toggle='tooltip']`).tooltip();

        // Record login analytics
        this._recordGaLogin(this.sessionStorageService.getItem(`recordGaLogin`) as LoginMethod);
    }

    /**
     * Sends analytics when clicking promo link
     */
    public static _sendAnalytics() {
        const gaPromotion: GaPromotion = JSON.parse($(this).attr(`data-promotion`));
        gaSelectPromotion([gaPromotion]);
    }

    /**
     * Track Promo impressions
     * @private
     */
    private _trackPromoImpressions() {
        setTimeout(() => {
            // setTimout runs async and this pause is needed so the dom elements can be drawn
            const promotions: GaPromotion[] = [];
            let sendPromos = false;
            if (this.promotions && this.promotions.length > 0) {
                this.promotions.each((i, promo: HTMLElement) => {
                    const promotion: GaPromotion = JSON.parse($(promo).attr(`data-promotion`));
                    const promotionId = `${promotion.creative_name}-${promotion.creative_slot}`;
                    if (InViewport.inViewport(promo, false) && this.promoFiredArray.indexOf(promotionId) === -1) {
                        promotions.push(promotion);
                        this.promoFiredArray.push(promotionId);
                    }
                    if (promotions.length > 0) {
                        sendPromos = true;
                    }
                });
                if (sendPromos) {
                    gaViewPromotion(promotions);
                }
            }
        }, 1000);
    }

    /**
     * Displays LiveChat chat window
     */
    public static showChatWindow(eventObject: JQueryMouseEventObject) {
        eventObject.preventDefault();
        setTimeout(() => {
            if (window && window.LC_API) {
                window.LC_API.open_chat_window();
            }
        }, 1000);
    }

    /**
     * Displays ContactInfoSuccessModal
     */
    public showVerifyContactInfoSuccessModal() {
        const [root] = this._reactClientService.appendComponent(
            ContactInfoSuccessModal,
            {
                onClose: () => {
                    root.unmount();
                },
                show: true,
            },
            reactModalElement,
        );
    }

    /**
     * Displays Feedback window (currently handled by Qualtrics)
     */
    public static showFeedbackWindow(eventObject: JQueryMouseEventObject) {
        eventObject.preventDefault();
        gaLegacyDataLayerPush(`injectQualtricsScript`);
    }

    /**
     * Deletes tracking cookies if user has opted out of tracking
     * @private
     */
    private _clearTrackingCookies() {
        const optanonConsentCookie = this.cookiesService.getCookie(`OptanonConsent`);
        if (optedOutOfTracking(optanonConsentCookie)) {
            this.cookiesService.deleteTrackingCookies().catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            });
        }
    }

    /**
     * TBD
     * @private
     */
    private _trackWebVitals() {
        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
        function sendToGTM({name, delta, id}) {
            dataLayer.push({
                event: `web-vitals`,
                event_category: `Web Vitals`,
                event_action: name,
                event_value: Math.round(name === `CLS` ? delta * 1000 : delta),
                event_label: id,
            });
        }

        getCLS(sendToGTM);
        getFID(sendToGTM);
        getLCP(sendToGTM);
    }

    /**
     * Presents ContactInfoComponent if contactVerify and no pending actions
     * @private
     */
    private _verifyContactInfo() {
        if (!this.userStateService.hasPendingActions() && this.currentUser.contactVerify) {
            this.contactsService
                .getContactInfo()
                .then((getContactInfoRes) => {
                    const [root] = this._reactClientService.appendComponent(
                        ContactInfoModal,
                        {
                            contactAddressArray: getContactInfoRes.address || [],
                            onClose: () => {
                                root.unmount();
                            },
                            onSuccess: this.showVerifyContactInfoSuccessModal,
                            show: true,
                        },
                        reactModalElement,
                    );
                })
                .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.error(e);
                });
        }
    }
}

const userData: User = JSON.parse(document.getElementById(`userData`)?.innerHTML || '{}'); // From app.ejs

AppComponent._initBugsnag();
const _fetchService = new FetchService();
const _localStorageService = new LocalStorageService();
const _authenticationService = new AuthenticationService(_fetchService);
const _autoReordersService = new AutoReordersService(_fetchService);
const _cmsService = new CmsService(_fetchService);
const _contactsService = new ContactsService(_fetchService);
const _cookiesService = new CookiesService(_fetchService);
const _jobCostingService = new JobCostingService(_fetchService);
const _listsService = new ListsService(_fetchService);
const _monetateService = new MonetateService(_fetchService);
const _netResultsService = new NetResultsService(_fetchService);
const _punchoutService = new PunchoutService(_fetchService);
const _searchService = new SearchService(_fetchService);
const _sessionStorageService = new SessionStorageService(_cookiesService);
const _signalsService = new SignalsService(_fetchService);
const _trackingService = new TrackingService(_fetchService);
const _userStateService = new UserStateService(_localStorageService);
const _usersService = new UsersService(_fetchService, reactModalElement, _sessionStorageService, userData, _userStateService);
const _creditCardsService = new CreditCardsService(_fetchService, _usersService, _userStateService);
const _itemsService = new ItemsService(_fetchService, _sessionStorageService, _usersService);
const _listsWorkflow = new ListsWorkflow(_listsService, _usersService, _userStateService);
const _dealsService = new DealsService(_fetchService, _sessionStorageService);
const _ordersService = new OrdersService(_fetchService, _usersService);
const _ordersWorkflow = new OrdersWorkflow(_ordersService, _usersService, _userStateService);
const _promosService = new PromosService(_fetchService, _ordersService, _ordersWorkflow, _usersService);
const _scannerWorkflow = new ScannerWorkflow(_usersService, _userStateService);
const _orderItemsService = new OrderItemsService(_fetchService, _localStorageService, _monetateService, _ordersService, _userStateService);
const _orderItemsWorkflow = new OrderItemsWorkflow(
    _orderItemsService,
    _ordersService,
    _ordersWorkflow,
    _sessionStorageService,
    _usersService,
    _userStateService,
);

export const appComponent = new AppComponent(
    _authenticationService,
    _autoReordersService,
    _cmsService,
    _contactsService,
    _cookiesService,
    _creditCardsService,
    _dealsService,
    _fetchService,
    _itemsService,
    _jobCostingService,
    _listsService,
    _listsWorkflow,
    _localStorageService,
    _monetateService,
    _netResultsService,
    _orderItemsService,
    _orderItemsWorkflow,
    _ordersService,
    _ordersWorkflow,
    _promosService,
    _punchoutService,
    _scannerWorkflow,
    _searchService,
    _sessionStorageService,
    _signalsService,
    _trackingService,
    _usersService,
    _userStateService,
);
initContext(appComponent);
appComponent.init();
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').catch((registrationError) => {
            // eslint-disable-next-line
            console.log('SW registration failed: ', registrationError);
        });
    });
}
