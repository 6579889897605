module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
"use strict";
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<form\naction=\"/select-account\"\nmethod=\"post\"\n>\n<input\nname=\"_csrf\"\ntype=\"hidden\"\nvalue=\"<%=locals.csrfToken%>\"\n>\n<input\nname=\"url\"\ntype=\"hidden\"\nvalue=\"<%=locals.redirectUrl || '/'%>\"\n>\n<%if (locals.accountNumber) {%>\n<input\naria-label=\"Account Number\"\nname=\"accountNumber\"\nvalue=\"<%=locals.accountNumber%>\"\n>\n<%}%>\n<%if (locals.contactID) {%>\n<input\naria-label=\"Contact ID\"\nname=\"contactID\"\nvalue=\"<%=locals.contactID%>\"\n>\n<%}%>\n<%if (locals.accountEntry) {%>\n<input\naria-label=\"Account Entry\"\nname=\"accountEntry\"\nvalue=\"<%=locals.accountEntry%>\"\n>\n<%}%>\n</form>"
  , __filename = "src\\client\\users\\_select-account-form.ejs";
try {
  var __output = [], __append = __output.push.bind(__output);
    ; __append("<form\naction=\"/select-account\"\nmethod=\"post\"\n>\n<input\nname=\"_csrf\"\ntype=\"hidden\"\nvalue=\"")
    ; __line = 8
    ; __append(escapeFn(locals.csrfToken))
    ; __append("\"\n>\n<input\nname=\"url\"\ntype=\"hidden\"\nvalue=\"")
    ; __line = 13
    ; __append(escapeFn(locals.redirectUrl || '/'))
    ; __append("\"\n>\n")
    ; __line = 15
    ; if (locals.accountNumber) {
    ; __append("\n<input\naria-label=\"Account Number\"\nname=\"accountNumber\"\nvalue=\"")
    ; __line = 19
    ; __append(escapeFn(locals.accountNumber))
    ; __append("\"\n>\n")
    ; __line = 21
    ; }
    ; __append("\n")
    ; __line = 22
    ; if (locals.contactID) {
    ; __append("\n<input\naria-label=\"Contact ID\"\nname=\"contactID\"\nvalue=\"")
    ; __line = 26
    ; __append(escapeFn(locals.contactID))
    ; __append("\"\n>\n")
    ; __line = 28
    ; }
    ; __append("\n")
    ; __line = 29
    ; if (locals.accountEntry) {
    ; __append("\n<input\naria-label=\"Account Entry\"\nname=\"accountEntry\"\nvalue=\"")
    ; __line = 33
    ; __append(escapeFn(locals.accountEntry))
    ; __append("\"\n>\n")
    ; __line = 35
    ; }
    ; __append("\n</form>")
    ; __line = 36
  return __output.join("");
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}