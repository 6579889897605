import * as React from 'react';
import {Button} from 'react-bootstrap';
import {useEffect} from 'react';

import {pushGaEvent} from '../../../client/ga/ga.functions';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';

export interface NullResultsProps {
    searchString: string;
}

export const NullResults = ({searchString}: NullResultsProps) => {
    // Fire analytics on display
    useEffect(() => {
        pushGaEvent(`ga4_search_overlay_null_result`, {had_search_results: false, search_input: searchString});
    }, [searchString]);

    /**
     * Template
     */
    return (
        <>
            <h1>
                0 results found for "<span className="text-red">{searchString}</span>"
            </h1>
            <div className="row mb-4">
                <div className="col-md-6 col-lg-7">
                    <div className="row h-100">
                        <div className="col-6 col-lg-4 mb-3 mb-md-0">
                            <div className="card h-100">
                                <div className="card-body d-flex align-items-center">
                                    <div>
                                        <div className="font-weight-bold">Use Exact Phrase</div>
                                        <div className="caption text-wrap">Enter an item/model number or brand</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-4 mb-3 mb-md-0">
                            <div className="card h-100">
                                <div className="card-body d-flex align-items-center">
                                    <div>
                                        <div className="font-weight-bold">Check Spelling</div>
                                        <div className="caption text-wrap">Even a small typo can make a big difference</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-none d-lg-block">
                            <div className="card h-100">
                                <div className="card-body d-flex align-items-center">
                                    <div>
                                        <div className="font-weight-bold">Try Different Words</div>
                                        <div className="caption text-wrap">
                                            You will get different results when searching 'glove' vs 'nitrile gloves'
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-5">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-4">
                            <div className="card gray-100-bg border-0">
                                <div className="card-body">
                                    <div className="font-weight-bold">We can help you find it.</div>
                                    <div className="mb-3 caption">
                                        We are available M-F, 6 AM to 6 PM CST <br />
                                        Call{' '}
                                        <a
                                            href="tel:18005582808"
                                            onClick={() => {
                                                gaLegacyCustomEvent({
                                                    eventAction: `null_search_phone_number_click`,
                                                    eventCategory: `Search Overlay`,
                                                    eventLabel: searchString.trim(),
                                                });
                                                pushGaEvent(`ga4_search_overlay_null_action`, {
                                                    click_type: `click_to_call`,
                                                    search_input: searchString,
                                                });
                                            }}
                                        >
                                            1-800-558-2808
                                        </a>
                                    </div>
                                    <Button
                                        block={true}
                                        onClick={() => {
                                            (window as any)?.LC_API?.open_chat_window();
                                            gaLegacyCustomEvent({
                                                eventAction: `null_search_start_chat`,
                                                eventCategory: `Search Overlay`,
                                                eventLabel: searchString.trim(),
                                            });
                                            pushGaEvent(`ga4_search_overlay_null_action`, {
                                                click_type: `start_chat`,
                                                search_input: searchString,
                                            });
                                        }}
                                        variant="secondary"
                                    >
                                        Start Chat
                                    </Button>
                                    <Button
                                        block={true}
                                        className="btn btn-secondary btn-block"
                                        href="/contact-us"
                                        onClick={() => {
                                            gaLegacyCustomEvent({
                                                eventAction: `null_search_send_email`,
                                                eventCategory: `Search Overlay`,
                                                eventLabel: searchString.trim(),
                                            });
                                            pushGaEvent(`ga4_search_overlay_null_action`, {
                                                click_type: `send_email`,
                                                search_input: searchString,
                                            });
                                        }}
                                        variant="secondary"
                                    >
                                        Send Email
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
