import styled from '@emotion/styled';

import {colors} from '../../ui/theme';

export const FooterStyles = styled.div`
    .main-footer {
        border-top: 5px solid ${colors.gray[600]};
        background-color: ${colors.gray[100]};
        font-size: 16px;

        .social-icons {
            a {
                color: ${colors.gray[700]};
                margin-right: 20px;

                &:hover {
                    text-decoration: none;
                }
            }

            a:last-child {
                margin-right: 0;
            }

            .fa {
                font-size: 54px;
            }
        }

        ul {
            margin-top: 16px;
            padding: 0;
            list-style-type: none;

            li {
                padding-bottom: 4px;
                line-height: 32px;

                a {
                    color: ${colors.gray[700]} !important;
                }
            }

            li.heading {
                padding-bottom: 10px;
            }
        }

        .track-order-btn {
            margin-left: -5px;
            height: 48px;
            width: 48px;
            text-align: center;
            margin-top: 0;
            background-color: ${colors.gray[550]};
            color: ${colors.white[100]};
            border: 0;
        }

        .app-button {
            width: calc(100% - 20px);
        }
    }

    .bottom-footer {
        background-color: ${colors.gray[600]};
        color: ${colors.gray[100]};

        a {
            color: ${colors.gray[100]} !important;
        }

        .credit-cards {
            color: ${colors.gray[900]};

            img {
                width: 48px;
            }

            .fa {
                font-size: 40px;
            }
        }

        .bottom-footer-navigation {
            line-height: 1.5;

            ul {
                list-style-type: none;
                margin-top: 20px;
                margin-bottom: 10px;
                text-align: center;
            }

            li {
                display: inline;
                border-right: 1px solid ${colors.gray[100]};
                padding: 0 15px 0 10px;

                a {
                    color: ${colors.gray[100]} !important;
                }
            }

            li:last-of-type {
                border-right: 0;
            }
        }
    }

    .toggle-footer-menu {
        color: ${colors.gray[750]};

        button {
            text-decoration: none !important;
            color: inherit;
        }
    }

    @media (max-width: 1024.98px) {
        .toggle-footer-menu {
            background-color: ${colors.gray[600]};
            color: ${colors.white[100]};
            height: 48px;
            line-height: 48px;
            border-bottom: 1px solid ${colors.gray[150]};
        }
    }
`;
